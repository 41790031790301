import {
  ApplicationRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {filter, Subject, take, tap} from "rxjs";
import {MapFile} from "../../core/model/map-file";
import {Store} from "@ngrx/store";
import {MapFilesSelector} from "../../core/ngrx/selectors/map-files.selector";
import * as _ from "lodash";
import {mapConfigActions, mapFilesActions} from '../../core/ngrx/actions';
import {UserMap} from "../../core/model/user-map";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";

@Component({
  selector: 'app-files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilesTableComponent implements OnInit, OnDestroy {

  @Input()
  _mapFiles: MapFile[] = [];
  @Output()
  onFileToggle: EventEmitter<MapFile> = new EventEmitter<MapFile>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  changingFile: MapFile = null;

  constructor(private readonly store: Store, private mapConfigSelector: MapConfigSelector,
              private appRef: ApplicationRef) {

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @Input() set mapFiles(mapFiles: MapFile[]) {
    this.mapConfigSelector
      .getUserMap()
      .pipe(
        filter((userMap: UserMap) => !!userMap),
        take(1),
        tap((userMap: UserMap) => {
          this._mapFiles = _.cloneDeep(mapFiles);
        })
      )
      .subscribe();
  }

  toggleSelect(selectedFile: MapFile) {
    selectedFile.selected = !selectedFile.selected;
    this.store.dispatch(
      mapFilesActions.fileToggle({mapFile: selectedFile})
    );
  }

  onShow() {
    this.appRef.tick();
  }

  onHide() {
    this.store.dispatch(
      mapFilesActions.fileColorChanged({mapFile: this.changingFile})
    );
    this.changingFile = null;
  }

  colorChanged(event, mapFile) {
    mapFile.color = event.value;
    this.changingFile = mapFile;
  }

}
