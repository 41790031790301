import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LandMapLayer } from '../model/land';
import { AppStateService } from './app-state.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandService {
  private apiUrl = `${environment.apiUrl}/land-parcel`;
  private get userId(): string {
    return this.appStateService.user?.userId;
  }

  constructor(private http: HttpClient, private appStateService: AppStateService) {}

  listLands(): Observable<LandMapLayer[]> {
    return this.http.get<LandMapLayer[]>(`${this.apiUrl}/${this.userId}`);
  }

  getLand(mapId: string): Observable<LandMapLayer> {
    return this.http.get<LandMapLayer>(`${this.apiUrl}/${this.userId}/${mapId}`);
  }

  saveLand(network: LandMapLayer): Observable<LandMapLayer> {
    return this.http.post<LandMapLayer>(`${this.apiUrl}`, network);
  }

  deleteLand(mapId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${this.userId}/${mapId}`);
  }
}