import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FiberNetworkMapLayer } from '../model/network';
import { AppStateService } from './app-state.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiberNetworkService {
  private apiUrl = `${environment.apiUrl}/fiber-network`;
  private get userId(): string {
    return this.appStateService.user?.userId;
  }

  constructor(private http: HttpClient, private appStateService: AppStateService) {}

  listFiberNetworks(): Observable<FiberNetworkMapLayer[]> {
    return this.http.get<FiberNetworkMapLayer[]>(`${this.apiUrl}/${this.userId}`);
  }

  getFiberNetwork(mapId: string): Observable<FiberNetworkMapLayer> {
    return this.http.get<FiberNetworkMapLayer>(`${this.apiUrl}/${this.userId}/${mapId}`);
  }

  saveFiberNetwork(network: FiberNetworkMapLayer): Observable<FiberNetworkMapLayer> {
    return this.http.post<FiberNetworkMapLayer>(`${this.apiUrl}`, network);
  }

  deleteFiberNetwork(mapId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${this.userId}/${mapId}`);
  }
}