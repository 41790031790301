import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {MapConfigStateService} from "../../service/map-config-state.service";
import {GeoService} from "../../service/geo.service";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {OAuthService} from "angular-oauth2-oidc";
import {authActions} from "../actions"

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    private oauthService: OAuthService
  ) {
  }


}
