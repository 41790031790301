import {NgModule} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {ListboxModule} from 'primeng/listbox';
import {TableModule} from "primeng/table";
import {MessagesModule} from "primeng/messages";
import {MessageModule} from "primeng/message";
import {ToastModule} from "primeng/toast";
import {CheckboxModule} from "primeng/checkbox";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {MenubarModule} from "primeng/menubar";
import {RadioButtonModule} from "primeng/radiobutton";
import {DropdownModule} from "primeng/dropdown";
import {DynamicDialogModule} from "primeng/dynamicdialog";
import {TabViewModule} from "primeng/tabview";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {MultiSelectModule} from "primeng/multiselect";
import {SliderModule} from "primeng/slider";
import {AccordionModule} from "primeng/accordion";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ColorPickerModule} from "primeng/colorpicker";
import {ToggleButtonModule} from "primeng/togglebutton";
import {InputNumberModule} from "primeng/inputnumber";


@NgModule({
  declarations: [],
  imports: [],
  exports: [
    ButtonModule,
    ListboxModule,
    TableModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    CheckboxModule,
    ScrollPanelModule,
    ToolbarModule,
    FileUploadModule,
    MenubarModule,
    RadioButtonModule,
    DropdownModule,
    DynamicDialogModule,
    TabViewModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    MultiSelectModule,
    SliderModule,
    AccordionModule,
    InputTextareaModule,
    ColorPickerModule,
    ToggleButtonModule,
    InputNumberModule
  ],
})
export class SharedPrimeNgModule {
}
