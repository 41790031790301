import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from "../../../environments/environment";

export const authCodeFlowConfig: AuthConfig = {
  issuer: `https://cognito-idp.us-west-1.amazonaws.com/${environment.cognitoUserPoolId}`,
  redirectUri: `${environment.envUrl}`,
  clientId: `${environment.cognitoClientId}`,
  responseType: 'token',
  scope: 'openid email phone profile',
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: true,
  oidc: true,
  logoutUrl: `${environment.cognitoDomain}/logout?client_id=${environment.cognitoClientId}&logout_uri=${environment.envUrl}`
};


export const BUSINESS_ERROR = "E00001"
export const TECHNICAL_ERROR = "E00002"
export const TOKEN_EXPIRED_ERROR = "E00003"
export const INVALID_TOKEN_ERROR = "E00004"
export const UNAUTHORIZED_ERROR = "E00005"
