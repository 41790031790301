export const SOURCE_ID = "sourceId";
export const MARKET_LABEL_LAYER_ID = "marketLabelLayerId"
export const MARKET_CIRCLE_LAYER_ID = "marketCircleLayerId"
export const MARKET_CIRCLE_FILL_LAYER_ID = "marketCircleFillLayerId"
export const SQUARE_INFO_FILL_LAYER_ID = "squareInfoFillLayerId"
export const SQUARE_INFO_TEXT_LAYER_ID = "squareInfoTextLayerId"
export const DISTANCE_LAYER_ID = "distanceLayerId"
export const POLYGON_LAYER_ID = "polygonLayerId"

export const MARKET_LABEL_SOURCE_TYPE = "marketLabelSourceType"
export const MARKET_LINES_SOURCE_TYPE = "marketLinesSourceType"
export const DISTANCE_LABEL_SOURCE_TYPE = "distanceLabelSourceType"
export const POLYGON_LABEL_SOURCE_TYPE = "polygonLabelSourceType"
export const DEFAULT_POLYGON_COLOR = "#FFA500"
export const DEFAULT_POLYGON_OPACITY = 0.4

export const SATELLITE_STYLE = "satellite";

export const INFO_SQUARE_SOURCE_TYPE = "infoSquareSourceType"
export const INFO_SQUARE_TEXT_SOURCE_TYPE = "infoSquareTextSourceType"


export const MAP_DRAW_STYLE = [
  // ACTIVE (being drawn)
  // line stroke
  {
    "id": "gl-draw-line",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#627BC1",
      "line-dasharray": [0.2, 2],
      "line-width": 2
    }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    "paint": {
      "fill-color": ['get', 'user_color'],
      "fill-outline-color": "#627BC1",
      "fill-opacity": ['get', 'user_opacity']
    }
  },
  // polygon mid points
  {
    'id': 'gl-draw-polygon-midpoint',
    'type': 'circle',
    'filter': ['all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#627BC1'
    }
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    "id": "gl-draw-polygon-stroke-active",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#403f3d",
      "line-dasharray": [0.2, 2],
      "line-width": 1
    }
  },
  // vertex point halos
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 5,
      "circle-color": "#FFF"
    }
  },
  // vertex points
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 3,
      "circle-color": "#D20C0C",
    }
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
    "id": "gl-draw-line-static",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#000",
      "line-width": 3
    }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill-static",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    "paint": {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1
    }
  },
  // polygon outline
  {
    "id": "gl-draw-polygon-stroke-static",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#000",
      "line-width": 3
    }
  }
];

// Fiber optic propagation speed (km/μs)
export const FIBER_OPTIC_SPEED = 204.357;
