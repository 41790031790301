<router-outlet></router-outlet>
<p-dialog header="Error" [(visible)]="visible" [modal]="true"
          [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false"
          [closeOnEscape]="false" [closable]="false">
  <div *ngIf="authError">
    <p class="m-0" *ngIf="authError.errorCode == tokenExpiredError">
      Your session has expired, please login again
    </p>
    <p class="m-0" *ngIf="authError.errorCode == invalidTokenError">
      Unauthorized, please login
    </p>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Go to login page" (onClick)="goToLogin()" styleClass="p-button-sm primary"
              class="primary">
    </p-button>
  </ng-template>
</p-dialog>
