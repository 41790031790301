import {createAction, props} from "@ngrx/store";
import {MapboxUser} from "../../model/mapbox-user";
import {LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS} from "./auth.actions";
import {UserMap} from "../../model/user-map";

export const GET_USERS = '[Users] Get Users';
export const GET_USERS_SUCCESS = '[Users] Get Users Success';
export const GET_USERS_FAILURE = '[Users] Get Users Failure';

export const SHARE_MAP = '[Users] Share Map';
export const SHARE_MAP_SUCCESS = '[Users] Share Map Success';
export const SHARE_MAP_FAILURE = '[Users] Share Map Failure';


export const getUsers = createAction(
  GET_USERS
);

export const getUsersSuccess = createAction(
  GET_USERS_SUCCESS,
  props<{ users: MapboxUser[] }>()
);

export const getUsersFailure = createAction(
  GET_USERS_FAILURE,
  props<{ msg: string }>()
);

export const shareMap = createAction(
  SHARE_MAP,
  props<{ userMap: UserMap }>()
);

export const shareMapSuccess = createAction(
  SHARE_MAP_SUCCESS
);

export const shareMapFailure = createAction(
  SHARE_MAP_FAILURE,
  props<{ msg: string }>()
);

