import { Nestable } from '../model/summary';

export class ColorUtils {
  static getProviderColor(provider: string, providers: Nestable[]): string {
    const providerObj = providers.find(p => p.label === provider);
    return providerObj ? providerObj.color : '#000000';
  }

  static generateRandomColor(): string {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
  }
}

