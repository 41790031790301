import {Injectable} from "@angular/core";
import {createFeatureSelector, createSelector, Store} from "@ngrx/store";
import * as fromUsers from "../reducers/users.reducer";
import {Observable} from "rxjs";
import {GeoInfo} from "../../model/geo-info";
import * as fromGeo from "../reducers/geo.reducer";
import {MapboxUser} from "../../model/mapbox-user";

@Injectable()
export class UsersSelector {
  constructor(private readonly store: Store) {
  }

  getUsersState = createFeatureSelector<fromUsers.State>('users');

  getUsers(): Observable<MapboxUser[]> {
    return this.store.select((createSelector(
      this.getUsersState,
      (state: fromUsers.State) => state.existingUsers
    )));
  };
}
