import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {OAuthService} from "angular-oauth2-oidc";
import {AuthSelector} from "../ngrx/selectors/auth.selector";
import {filter, take, tap} from "rxjs";
import {AppStateService} from "../service/app-state.service";

@Injectable()
export class AuthGuard  {

  constructor(private oauthService: OAuthService, private authSelector: AuthSelector,
              private appStateSeervice: AppStateService) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
    let user = await this.authSelector.getLoggedUser().pipe(
      filter(user => !!user),
      take(1)
    ).toPromise();
    const claims = this.oauthService.getIdentityClaims();
    if (claims && user) {
      if (!this.appStateSeervice.user) {
        this.appStateSeervice.user = user;
      }
      return true;
    } else {
      return false;
    }
  }
}
