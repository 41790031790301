import {Datacenter} from "./datacenter";
import {MapLine, MapPolygon} from "./summary";
import {MapboxUser} from "./mapbox-user";
import {UserMapFileModel} from "./user-map-file";

export interface UserMap {
  mapId?: string;
  userId?: string;
  mapName: string;
  creationDate?: Date
  modificationDate?: Date
  modifiedBy?: string;
  desc?: string;
  units?: DistanceUnits;
  mapLines?: MapLine[];
  mapPolygons?: MapPolygon[];
  datacenters?: Datacenter[];
  mwThreshold?: number;
  defaultColor?: string;
  defaultFont?: string;
  sharedWith?: MapboxUser[];
  shared?: boolean;
  owner?: string;
  files?: UserMapFileModel[]
}

export enum DistanceUnits {
  MILES = 'miles', KMS = 'kilometers'
}
