import {Component, OnDestroy, OnInit} from '@angular/core';
import {MemoryStorage, OAuthService} from "angular-oauth2-oidc";
import {
  authCodeFlowConfig,
  BUSINESS_ERROR,
  INVALID_TOKEN_ERROR,
  TOKEN_EXPIRED_ERROR
} from "./core/consts/auth.constants";
import {Router} from "@angular/router";
import {MapboxUser} from "./core/model/mapbox-user";
import {Store} from "@ngrx/store";
import {authActions} from "./core/ngrx/actions";
import {filter, Observable, Subject, takeUntil, tap} from "rxjs";
import {AuthSelector} from "./core/ngrx/selectors/auth.selector";
import {AppError} from "./core/model/app-error";
import {AppStateService} from "./core/service/app-state.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'WebApp';
  destroy$: Subject<boolean> = new Subject<boolean>();
  visible = false;
  authError: AppError = null;

  constructor(public oauthService: OAuthService, private router: Router, private readonly store: Store,
              private authSelector: AuthSelector, private appStateService: AppStateService,
              private memoryStorage: MemoryStorage) {

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.setStorage(localStorage);

    this.authSelector.isLoggedOut().pipe(
      takeUntil(this.destroy$),
      filter((isLoggedOut: boolean) => isLoggedOut),
      tap((isLoggedOut: boolean) => {
        this.oauthService.logOut(true);
        window.location.reload();
      })
    ).subscribe();

    this.authSelector.getAuthError().pipe(
      takeUntil(this.destroy$),
      filter((error: AppError) => !!error),
      tap((error: AppError) => this.handleAuthError(error))
    ).subscribe();


    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(isLoggedIn => {
      const claims = this.oauthService.getIdentityClaims();
      if (isLoggedIn) {
        this.oauthService.loadUserProfile().then((profile: any) => {
          const loggedUser: MapboxUser = {
            email: profile.info.email,
            firstName: profile.info.given_name,
            lastName: profile.info.family_name,
            userId: profile.info.username,
            username: profile.info.username,

          }
          this.store.dispatch(authActions.login({
            user: loggedUser
          }));
        })
      } else if (claims) {
        const loggedUser: MapboxUser = {
          email: claims['email'],
          firstName: claims['given_name'],
          lastName: claims['family_name'],
          userId: claims['cognito:username'],
          username: claims['cognito:username'],
        }
        this.store.dispatch(authActions.login({
          user: loggedUser
        }));
      } else {
        this.oauthService.initLoginFlow();
      }
    });
  }

  handleAuthError(error: AppError) {
    this.visible = true;
    this.authError = error;
  }

  goToLogin() {
    this.store.dispatch(authActions.logout());
  }

  get tokenExpiredError(): string {
    return TOKEN_EXPIRED_ERROR;
  }

  get invalidTokenError(): string {
    return INVALID_TOKEN_ERROR;
  }
}
