<div style="height: 644px; min-height: 644px; overflow: hidden">
    <p-table
      id="landsList"
      #dt1
      selectionMode="multiple"
      [(selection)]="selectedLands"
      [scrollable]="true"
      scrollHeight="flex"
      [value]="lands"
      [rowHover]="true"
      (onRowSelect)="selectedLandsChanged()"
      (onRowUnselect)="selectedLandsChanged()"
      [lazy]="false"
      dataKey="id"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 60px"></th>
          <th></th>
          <th style="width: 60px">
            <span *ngIf="!onlyVisible">
              <a
                (click)="toggleAll()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px; color: white;"
                class="pi pi-eye"
              ></a>
            </span>
            <span *ngIf="onlyVisible">
              <a
                (click)="toggleAll()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px; color: white;"
                class="pi pi-eye-slash"
              ></a>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-land>
        <tr [pSelectableRow]="land" class="p-selectable-row">
          <td style="width: 60px">
            <p-colorPicker
              [appendTo]="'body'"
              (click)="onShow()"
              (onHide)="onHide()"
              (onChange)="colorChanged($event, land)"
              [(ngModel)]="land.color"
            ></p-colorPicker>
          </td>
          <td style="cursor: pointer;" (click)="onLandClick(land)">
            {{ land.propertyOwner }}
          </td>
          <td style="width: 60px">
            <span *ngIf="onlyVisible">
              <a
                (click)="hideLand()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px"
                class="pi pi-eye"
              ></a>
            </span>
            <span *ngIf="!onlyVisible">
              <a
                (click)="hideLand()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px"
                class="pi pi-eye-slash"
              ></a>
            </span>
            <p-button
            icon="pi pi-trash"
            styleClass="p-button-sm primary btn-create"
            (onClick)="deleteLand(land)"
            pTooltip="Remove Land"
            tooltipPosition="bottom"
          ></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-confirmDialog
  acceptButtonStyleClass="primary"
  rejectButtonStyleClass="secondary"
  [style]="{ width: '35vw' }"
  ></p-confirmDialog>