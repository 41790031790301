<div style="height: 644px; min-height: 644px; overflow: hidden">
  <p-table
    id="networksList"
    #dt1
    selectionMode="multiple"
    [(selection)]="selectedNetworks"
    [scrollable]="true"
    scrollHeight="flex"
    [value]="networks"
    [rowHover]="true"
    (onRowSelect)="selectedNetworksChanged()"
    (onRowUnselect)="selectedNetworksChanged()"
    [lazy]="false"
    dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 60px"></th>
        <th></th>
        <th style="width: 60px">
          <span *ngIf="!onlyVisible">
            <a
              (click)="toggleAll()"
              style="cursor: pointer; text-decoration: none; margin-top: 3px; color: white;"
              class="pi pi-eye"
            ></a>
          </span>
          <span *ngIf="onlyVisible">
            <a
              (click)="toggleAll()"
              style="cursor: pointer; text-decoration: none; margin-top: 3px; color: white;"
              class="pi pi-eye-slash"
            ></a>
          </span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-network>
      <tr [pSelectableRow]="network" class="p-selectable-row">
        <td style="width: 60px">
          <p-colorPicker
            [appendTo]="'body'"
            (click)="onShow()"
            (onHide)="onHide()"
            (onChange)="colorChanged($event, network)"
            [(ngModel)]="network.color"
          ></p-colorPicker>
        </td>
        <td style="cursor: pointer;" (click)="onNetworkClick(network)">
          {{ network.supplierName }}
        </td>
        <td style="width: 60px">
          <span *ngIf="onlyVisible">
            <a
              (click)="hideNetwork()"
              style="cursor: pointer; text-decoration: none; margin-top: 3px"
              class="pi pi-eye"
            ></a>
          </span>
          <span *ngIf="!onlyVisible">
            <a
              (click)="hideNetwork()"
              style="cursor: pointer; text-decoration: none; margin-top: 3px"
              class="pi pi-eye-slash"
            ></a>
          </span>
          <p-button
          icon="pi pi-trash"
          styleClass="p-button-sm primary btn-create"
          (onClick)="deleteNetwork(network)"
          pTooltip="Remove Network"
          tooltipPosition="bottom"
        ></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog
acceptButtonStyleClass="primary"
rejectButtonStyleClass="secondary"
[style]="{ width: '35vw' }"
></p-confirmDialog>