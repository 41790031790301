import { Component, Input } from '@angular/core';
import { MapLayer } from '../../core/model/map-layer.interface';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-map-layer-popup',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  template: `
    <div class="map-layer-popup">
      <div class="popup-header">
        <span class="popup-title">
            {{ layer.supplierName || layer.propertyOwner }}
            <span class="layer-type">{{ layer.type }}</span>
        </span>
      </div>
      
      <div class="popup-content">
        <ng-container *ngFor="let prop of displayableProperties">
          <div class="property-row">
            <span><b>{{ prop.label }}</b> - {{ formatPropertyValue(layer.properties[prop.key]) }}</span>
          </div>
        </ng-container>
        <div class="popup-actions">
          <p-button 
            (click)="showUnsupportedAlert('Modify')"
            styleClass="p-button-primary primary p-button-sm" 
            [label]="'Modify'"
          >
          </p-button>
          <p-button
            (click)="showUnsupportedAlert('Remove')"
            styleClass="p-button-primary secondary p-button-sm" 
            [label]="'Remove'"
          >
          </p-button>
          <p-button
            (click)="showUnsupportedAlert('Copy')" 
            styleClass="p-button-primary secondary p-button-sm" 
            icon="pi pi-copy"
          >
          </p-button>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .popup-title {
      font-size: 20px;
    }
    
    .layer-type {
      padding: 2px 8px;
      border-radius: 4px;
      background: #eee;
      font-size: 0.9em;
    }
    
    .property-row {
      margin: 8px 0;

      span {
        font-size: 12px;
        
        b {
          font-weight: bold;
        }
      }
    }
  `]
})
export class MapLayerPopupComponent {
  @Input() layer: MapLayer;
  
  showUnsupportedAlert(feature: string) {
    window.alert(`${feature} Feature not supported (working in progress)`);
  }
  
  formatPropertyValue(value: any): string {
    if (Array.isArray(value)) {
      return value.join(', ');
    }
    return value?.toString() || '';
  }
  
  get displayableProperties() {
    // TODO: Here you will configure the fields for each entity (which may change).
    const propertyConfigs = {
      network: [
        { key: 'serviceType', label: 'Service Type' },
        { key: 'regions', label: 'Regions' },
        { key: 'underDNA', label: 'Under DNA' }
      ],
      land: [
        { key: 'propertyOwner', label: 'Property Owner' },
        { key: 'underDNA', label: 'Under DNA' },
        { key: 'landType', label: 'Land Type' }
      ],
      power: [
        { key: 'serviceType', label: 'Service Type' },
        { key: 'regions', label: 'Regions' },
        { key: 'underDNA', label: 'Under DNA' }
      ],
    };
    
    return propertyConfigs[this.layer.type];
  }
} 