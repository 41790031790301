import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {MapConfigStateService} from "../../service/map-config-state.service";
import {MapService} from "../../service/map.service";
import {GeoService} from "../../service/geo.service";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {State} from "../reducers/map-config.reducer";
import {MapLine, MapPolygon} from "../../model/summary";
import {geoActions} from "../actions"
import {Injectable} from "@angular/core";
import {GET_LOCATION} from "../actions/geo.actions";

@Injectable()
export class GeoEffects {
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    public mapConfigState: MapConfigStateService,
    private geoService: GeoService
  ) {
  }

  queryGeo = createEffect(() =>
    this.actions$.pipe(
      ofType(geoActions.queryGeo),
      exhaustMap(action => this.geoService.queryGeoInfo(action.query).pipe(
        map(response => geoActions.queryGeoSuccess({geoInfo: response})),
        catchError((error: any) => of(geoActions.queryGeoFailure({msg: "error"})))
      ))
    )
  );

  getLocation = createEffect(() =>
    this.actions$.pipe(
      ofType(geoActions.getLocation),
      exhaustMap(action => this.geoService.getLocation(action.id).pipe(
        map(response => geoActions.getLocationSuccess({geoInfo: response})),
        catchError((error: any) => of(geoActions.getLocationFailure({msg: "Invalid search"})))
      ))
    )
  );

}
