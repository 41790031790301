import * as mapboxgl from "mapbox-gl";
import {Feature} from "@turf/turf";
import {
  DEFAULT_POLYGON_COLOR, DEFAULT_POLYGON_OPACITY,
  DISTANCE_LABEL_SOURCE_TYPE, FIBER_OPTIC_SPEED, INFO_SQUARE_SOURCE_TYPE, INFO_SQUARE_TEXT_SOURCE_TYPE,
  MARKET_LABEL_SOURCE_TYPE,
  MARKET_LINES_SOURCE_TYPE, POLYGON_LABEL_SOURCE_TYPE, SATELLITE_STYLE
} from "../../../../consts/map.constants";
import {getCenterPoint} from "./polygon-drawing.util";
import {MapPolygon, Nestable} from "../../../../model/summary";
import * as turf from "@turf/turf";
import * as _ from "lodash";
import {uniqueFilter} from "./data-center.util";
import {Datacenter, DatacenterBasicInfo} from "../../../../model/datacenter";
import {State} from "../../map-config.reducer";
import {DistanceUnits, UserMap} from "../../../../model/user-map";
import {getMarkersInsidePolygon} from "../../../../util/transform.util";

export const updateAreas = (state: State, action): State => {
  var fc = (_.cloneDeep(state.mapSource.data) as any)
  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: fc
  };
  const withoutMarkets = fc.features.filter(
    f => f.properties['type'] && f.properties['type'] != MARKET_LABEL_SOURCE_TYPE && f.properties['type'] != MARKET_LINES_SOURCE_TYPE);
  fc.features = withoutMarkets;
  for (const market of action.areas.filter(m => m.label)) {
    const circleFeature: Feature = createMarketBuffer(market);
    circleFeature.properties = {
      type: MARKET_LINES_SOURCE_TYPE,
      label: market.label
    };
    fc.features.push(circleFeature);

    const labelFeature: Feature = {
      type: "Feature",
      geometry: {
        'type': 'Point',
        'coordinates': [
          market.circleData.center[0], market.circleData.center[1]
        ]
      },
      properties: {
        type: MARKET_LABEL_SOURCE_TYPE,
        label: market.label
      }
    }
    fc.features.push(labelFeature);
  }
  return {
    ...state,
    mapSource: mapSource
  };
};

export const loadMapSource = (state: State, action): State => {
  //markets
  var fc = {"type": "FeatureCollection", "features": <any>[]}
  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: fc as any
  };

  for (const market of action.markets.filter(m => m.label)) {
    const circleFeature: Feature = createMarketBuffer(market) as any;
    circleFeature.properties = {
      type: MARKET_LINES_SOURCE_TYPE,
      label: market.label
    };
    fc.features.push(circleFeature);

    const labelFeature: Feature = {
      type: "Feature",
      geometry: {
        'type': 'Point',
        'coordinates': [
          market.circleData.center[0], market.circleData.center[1]
        ]
      },
      properties: {
        type: MARKET_LABEL_SOURCE_TYPE,
        label: market.label
      }
    }
    fc.features.push(labelFeature);
  }

  //lines
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };

  for (const mapLine of action.mapLines) {
    const line = turf.lineString([mapLine.pointA, mapLine.pointB]);
    const distanceKm = turf.length(line, {units: 'kilometers'});
    const latencyUs = (distanceKm / FIBER_OPTIC_SPEED).toFixed(1);
    
    const distancelabel = `${numberWithCommas(turf.length(line, {units: state.units}).toFixed(0))} ` +
      `${state.units == DistanceUnits.MILES ? 'miles' : 'kms'}\n` +
      `Latency: ${latencyUs}μs`;

    const lineFeature: Feature = {
      type: "Feature",
      id: mapLine.auxId,
      geometry: {
        type: "LineString",
        coordinates: [mapLine.pointA, mapLine.pointB]
      },
      properties: {
        label: distancelabel
      }
    };
    drawFeaturesCollection.features.push(lineFeature as any);

    const pointFeature: Feature = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [mapLine.pointA, mapLine.pointB]
      },
      properties: {
        label: distancelabel,
        id: mapLine.auxId,
        type: DISTANCE_LABEL_SOURCE_TYPE
      }
    };
    (mapSource.data as any).features.push(pointFeature);
  }

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    mapSource: mapSource
  };
}


export const removeDrawing = (state: State, action): State => {
  const exDrawFeature = state.drawingFeatures.features.filter(f => f.id != action.feature.id);
  const exSourceFeature = (state.mapSource.data as any).features.filter(
    f => !f.properties["id"] || action.feature.id != f.properties["id"]);

  var fc = {"type": "FeatureCollection", "features": <any>[]}
  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: fc as any
  };
  fc.features = _.cloneDeep(exSourceFeature)
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: _.cloneDeep(exDrawFeature)
  };
  let source = updateInfoSquare(mapSource, drawFeaturesCollection, state.datacenters);

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    mapSource: source
  };
}

export const loadUserMapSuccess = (state: State, action): State => {
  let providers: Nestable[] = _.cloneDeep(action.providers);
  let userMap: UserMap = _.cloneDeep(action.userMap);
  userMap.sharedWith = userMap.sharedWith ? userMap.sharedWith : [];
  if (state.providers && state.providers.length) {
    for (let prov of state.providers) {
      let existProv = providers.find(p => p.label == prov.label);
      if (existProv) {
        existProv.isProviderHidden = prov.isProviderHidden;
      }
    }
  } else {
    providers.forEach(p => p.isProviderHidden = false);
  }

  return {
    ...state,
    existingMarkets: action.userMap.datacenters.map(d => d.market).filter(uniqueFilter),
    existingProviders: action.userMap.datacenters.map(d => d.provider).filter(uniqueFilter),
    userMap: userMap,
    providers: providers,
    datacenters: action.userMap.datacenters,
    mwThreshold: action.userMap.mwThreshold,
    readOnly: action.readOnly,
    units: action.userMap.units ? action.userMap.units : DistanceUnits.MILES
  };
}

export const onMapStyleChanged = (state: State, action): State => {
  const drawFeaturesCollection = _.cloneDeep(state.drawingFeatures)
  if (action.layerId == SATELLITE_STYLE) {
    for (const feature of drawFeaturesCollection.features) {
      if (feature.geometry.type != "LineString") {
        feature.properties['opacity'] = 1;
      }
    }
  } else {
    for (const feature of drawFeaturesCollection.features) {
      if (feature.geometry.type != "LineString") {
        feature.properties['opacity'] = DEFAULT_POLYGON_OPACITY;
      }
    }
  }

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
  };
}

export const saveMarkerToDisplay = (state: State, action): State => {
  return {
    ...state,
    markerToDisplay: action.info
  };
}

export const popupDisplayed = (state: State, action): State => {
  return {
    ...state,
    markerToDisplay: null
  };
}

const createMarketBuffer = (market: Nestable) => {
  var point = turf.point([market.circleData.center[0], market.circleData.center[1]]);
  let ratio = market.circleData.ratio + ((market.circleData.ratio * 20) / 100);
  ratio = ratio <= 0 ? 1 : ratio;
  var buffered = turf.buffer(point, ratio, {units: 'miles'});
  return buffered;
}

const getSelectedDatacenters = (datacenters: Datacenter[]) => {
  return datacenters.filter(dc => dc.selected);
}

export const buildMapPolygonDataFromModel = (mapPolygon: MapPolygon, state): [any, any, any, any, any] => {
  const polygonFeature: Feature = {
    type: "Feature",
    id: mapPolygon.auxId,
    geometry: {
      type: "Polygon",
      coordinates: mapPolygon.polygon
    },
    properties: {
      hideDatacenters: mapPolygon.hideDatacenters,
      label: mapPolygon.label,
      color: mapPolygon.color ? mapPolygon.color : DEFAULT_POLYGON_COLOR,
      circleRadius: mapPolygon.circleRadius,
      opacity: mapPolygon.opacity ? mapPolygon.opacity : DEFAULT_POLYGON_OPACITY
    }
  };


  const centerComplex = getCenterPoint(polygonFeature)
  const center = [centerComplex.geometry.coordinates[0], centerComplex.geometry.coordinates[1]];

  const pointFeature: Feature = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: center
    },
    properties: {
      label: mapPolygon.label,
      id: mapPolygon.auxId,
      type: POLYGON_LABEL_SOURCE_TYPE
    }
  };


  let infoSquare = buildInfoSquarePolygon(mapPolygon.circleRadius, mapPolygon.auxId, polygonFeature, state.datacenters);

  return [polygonFeature, pointFeature, center, infoSquare[0], infoSquare[1]]
}

export const buildInfoSquarePolygon = (circleRadius: number, auxId: string, polygonFeature: Feature, datacenters: Datacenter[]): [any, any] => {
  let finalSquarePosition = null;
  let origPolygonSquare = null;
  let originalCenter = null;
  if (circleRadius == -1) {
    let origPolygonBbox = turf.bbox(polygonFeature as any);
    origPolygonSquare = turf.bboxPolygon(origPolygonBbox);
    originalCenter = turf.center(turf.points([[origPolygonSquare.bbox[2], origPolygonSquare.bbox[3]],
      [origPolygonSquare.bbox[2], origPolygonSquare.bbox[1]]]));
  } else {
    originalCenter = turf.center(polygonFeature);
    let circleBuffer = turf.buffer(originalCenter, circleRadius, {steps: 0});
    let origPolygonBbox = turf.bbox(circleBuffer as any);
    origPolygonSquare = turf.bboxPolygon(origPolygonBbox);
    originalCenter = turf.center(turf.points([[origPolygonSquare.bbox[2], origPolygonSquare.bbox[3]],
      [origPolygonSquare.bbox[2], origPolygonSquare.bbox[1]]]));
  }

  //creating scaled square
  let finalBuffer = turf.buffer(originalCenter, 10, {steps: 0});
  let finalBbox = turf.bbox(finalBuffer as any);
  let finalSquare = turf.bboxPolygon(finalBbox as any);

  finalSquarePosition = turf.transformTranslate(finalSquare, 6, 90);
  finalSquarePosition.properties["type"] = INFO_SQUARE_SOURCE_TYPE;

  let inside = getMarkersInsidePolygon(_.cloneDeep(datacenters.filter(dc => dc.selected)), polygonFeature as any)
  const squareInfoCenterComplex = getCenterPoint(finalSquarePosition)
  const squareInfoCenter = [squareInfoCenterComplex.geometry.coordinates[0], squareInfoCenterComplex.geometry.coordinates[1]];
  const squareInfoTextFeature: Feature = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: squareInfoCenter
    },
    properties: {
      label: `Live: ${_.sum(inside.map(dc => dc.mwLive))}\nU/C: ${_.sum(inside.map(dc => dc.mwUC))}\nPlan: ${_.sum(inside.map(dc => dc.mwPlanned))}`,
      id: auxId,
      type: INFO_SQUARE_TEXT_SOURCE_TYPE
    }
  };

  return [finalSquarePosition, squareInfoTextFeature];
}

export const updateInfoSquare = (mapSource: any, drawingFeatures: any, datacenters: Datacenter[]): any => {
  const exSourceFeature = (mapSource.data as any).features.filter(
    f => !f.properties["type"] || (f.properties["type"] != INFO_SQUARE_SOURCE_TYPE && f.properties["type"] != INFO_SQUARE_TEXT_SOURCE_TYPE));


  var fc = {"type": "FeatureCollection", "features": <any>[]}
  const newMapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: fc as any
  };
  fc.features = _.cloneDeep(exSourceFeature)
  const exDrawFeature = drawingFeatures.features;
  for (let feature of exDrawFeature) {
    if (feature.geometry.type != "LineString") {
      let infoSquare = buildInfoSquarePolygon(feature.properties["circleRadius"], feature.id.toString(), feature as any, datacenters);
      //fc.features.push(infoSquare[0]);
      fc.features.push(infoSquare[1])
    }

  }

  return newMapSource;

}

export const buildMapPolygonDataFromFeature = (feature: any): [any, any, any] => {
  const polygonFeature: Feature = {
    type: "Feature",
    id: feature.id,
    geometry: {
      type: "Polygon",
      coordinates: (feature.geometry as any).coordinates
    },
    properties: {
      hideDatacenters: feature.properties['hideDatacenters'] ? feature.properties['hideDatacenters'] : '',
      label: feature.properties['label'] ? feature.properties['label'] : '',
      color: feature.properties['color'] ? feature.properties['color'] : DEFAULT_POLYGON_COLOR,
      circleRadius: feature.properties['circleRadius'] ? feature.properties['circleRadius'] : -1,
      opacity: feature.properties['opacity'] ? feature.properties['opacity'] : DEFAULT_POLYGON_OPACITY,
    }
  };

  const centerComplex = getCenterPoint(polygonFeature)
  const center = [centerComplex.geometry.coordinates[0], centerComplex.geometry.coordinates[1]];

  const pointFeature: Feature = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: center
    },
    properties: {
      label: feature.properties['label'] ? feature.properties['label'] : '',
      id: feature.id,
      type: POLYGON_LABEL_SOURCE_TYPE
    }
  };

  return [polygonFeature, pointFeature, center]
}

export const buildMapPolygonModelFromFeature = (feature: any): [MapPolygon, any] => {
  const geometry = feature.geometry as any;
  const centerComplex = getCenterPoint(feature)
  const center = [centerComplex.geometry.coordinates[0], centerComplex.geometry.coordinates[1]];
  const mapPolygon: MapPolygon = {
    polygon: geometry.coordinates,
    auxId: feature.id.toString(),
    color: feature.properties["color"] ? feature.properties["color"] : DEFAULT_POLYGON_COLOR,
    label: feature.properties['label'] ? feature.properties["label"] : "",
    hideDatacenters: feature.properties['hideDatacenters'] ? feature.properties["hideDatacenters"] : false,
    circleRadius: feature.properties['circleRadius'] ? feature.properties["circleRadius"] : -1,
    opacity: feature.properties['opacity'] ? feature.properties["opacity"] : DEFAULT_POLYGON_OPACITY,
  };
  return [mapPolygon, center]
}

function numberWithCommas(x: string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
