import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MapboxUser} from "../../core/model/mapbox-user";
import {Observable, tap} from "rxjs";
import {UsersSelector} from "../../core/ngrx/selectors/users.selector";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {AuthSelector} from "../../core/ngrx/selectors/auth.selector";
import * as _ from "lodash";

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UsersListComponent implements OnInit {
  existingUsers: MapboxUser[];
  selectedUsers: MapboxUser[] = [];
  user: Observable<MapboxUser> = this.authSelector.getLoggedUser();

  constructor(private usersSelector: UsersSelector, public config: DynamicDialogConfig,
              private authSelector: AuthSelector, public ref: DynamicDialogRef) {

  }

  ngOnInit(): void {
    this.usersSelector.getUsers().pipe(
      tap((users: MapboxUser[]) => {
        this.selectedUsers = [];
        const allUsers = _.cloneDeep(users);
        allUsers.sort((a, b) => a.fullName.localeCompare(b.fullName));

        if (this.config.data.excludeSelf) {
          this.authSelector.getLoggedUser().subscribe((currentUser: MapboxUser) => {
            this.existingUsers = allUsers.filter(u => u.userId != currentUser.userId);
            this.addSelectedUsers();
          });
        } else {
          this.existingUsers = allUsers;
          this.addSelectedUsers();
        }
      })
    ).subscribe();
  }

  addSelectedUsers() {
    this.config.data.currentSharingUsers.forEach(csu => {
      const selectedUser = this.existingUsers.find(eu => {
        if(csu.userId){
          return eu.userId == csu.userId;
        }
        return eu.userId == csu

      });
      if(selectedUser){
        selectedUser.sharedMapFullAccess = csu.sharedMapFullAccess;
        this.selectedUsers.push(selectedUser);
      }
    });
  }

  share() {
    this.ref.close(this.selectedUsers ? this.selectedUsers : []);
  }

  isUserSelected(user: MapboxUser) {
    return !!this.selectedUsers.find(u=>u.userId == user.userId);
  }

  cancel() {
    this.ref.close(null);
  }

}
