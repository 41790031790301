import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {mapConfigActions} from '../actions'
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../reducers/map-config.reducer";
import {MapLine, MapPolygon} from "../../model/summary";
import {UserMap} from "../../model/user-map";
import {MapConfigStateService} from "../../service/map-config-state.service";
import {MapService} from "../../service/map.service";
import {DataCentersService} from "../../service/data-centers.service";
import * as _ from "lodash";
import {AppStateService} from "../../service/app-state.service";


@Injectable()
export class MapConfigEffects {
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    public mapConfigState: MapConfigStateService,
    private mapService: MapService,
    private dataCentersService: DataCentersService,
    private appStateService: AppStateService
  ) {
  }

  saveMap = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.saveMap),
      withLatestFrom(this.store),
      map(([action, store]) => {
          const currentState = (store as any).mapConfig as State;
          const userMap = _.cloneDeep(currentState.userMap);
          userMap.modifiedBy = this.appStateService.user.userId
          userMap.units = currentState.units;
          userMap.datacenters = currentState.datacenters;
          userMap.mapPolygons = [];
          userMap.mapLines = [];
          userMap.mwThreshold = currentState.mwThreshold;
          //create dtos from state
          for (const feature of currentState.drawingFeatures.features) {
            if (feature.geometry.type == "LineString") {
              const geometry = feature.geometry as any;
              const mapLine: MapLine = {
                auxId: feature.id.toString(),
                label: feature.properties['label'],
                pointA: [geometry.coordinates[0][0], geometry.coordinates[0][1]],
                pointB: [geometry.coordinates[1][0], geometry.coordinates[1][1]],
              };
              userMap.mapLines.push(mapLine);
            } else {
              const geometry = feature.geometry as any;
              const mapPolygon: MapPolygon = {
                polygon: geometry.coordinates,
                auxId: feature.id.toString(),
                label: feature.properties['label'],
                color: feature.properties['color'],
                circleRadius: feature.properties['circleRadius']
              };
              userMap.mapPolygons.push(mapPolygon);
            }
          }

          if (currentState.mapLines.length) {
            userMap.mapLines = userMap.mapLines.concat(currentState.mapLines);
          }
          if (currentState.mapPolygons.length) {
            userMap.mapPolygons = userMap.mapPolygons.concat(currentState.mapPolygons);
          }
          return userMap;
        }
      ),
      exhaustMap(userMap => this.mapService.saveUserMap(userMap).pipe(
        map(response => mapConfigActions.saveMapSuccess({userMap: response})),
        catchError((error: any) => of(mapConfigActions.saveMapError({msg: "error"})))
      ))
    )
  );

  savePartialMap = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.savePartialMap),
      exhaustMap(action => this.mapService.savePartialUserMap(action.userMap).pipe(
        map(response => mapConfigActions.saveMapSuccess({userMap: null})),
        catchError((error: any) => of(mapConfigActions.saveMapError({msg: "error"})))
      ))
    )
  );

  mergeMap = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.mergeMap),
      exhaustMap(action => this.mapService.mergeMap(action.maps, action.userMap, action.objectsToAdd).pipe(
        map(response => mapConfigActions.mergeMapSuccess({userMap: response})),
        catchError((error: any) => of(mapConfigActions.mergeMapError({msg: "error"})))
      ))
    )
  );

  loadUserMaps = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.loadUserMaps),
      exhaustMap(action => this.mapService.getUserMaps().pipe(
        map(response => mapConfigActions.loadUserMapsSuccess({userMaps: response})),
        catchError((error: any) => of(mapConfigActions.loadUserMapsError({msg: "error"})))
      ))
    )
  );

  uploadCsv = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.uploadCsv),
      exhaustMap(action => this.mapService.processCsv(action.mapId, action.data).pipe(
        map(response => mapConfigActions.uploadCsvSuccess({userMap: response})),
        catchError((error: any) => of(mapConfigActions.uploadCsvError({msg: error.error.data})))
      ))
    )
  );

  copyFromMap = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.copyFromMap),
      exhaustMap(action => this.mapService.copyFromMap(action.mapId, action.maptoCopy).pipe(
        map(response => mapConfigActions.copyFromMapSuccess({userMap: response})),
        catchError((error: any) => of(mapConfigActions.copyFromMapError({msg: "error"})))
      ))
    )
  );

  loadUserMap = createEffect(() =>
    this.actions$.pipe(
      ofType(mapConfigActions.loadUserMap),
      exhaustMap(action => this.mapService.getUserMap(action.userId, action.mapId, action.full).pipe(
        map(response => {
          let fullAccess = true;
          const userMap = response as UserMap;
          if (userMap.userId != this.appStateService.user.userId) {
            //is shared
            const sharingUser = userMap.sharedWith.find(u => u.userId == this.appStateService.user.userId);
            if (sharingUser && !sharingUser.sharedMapFullAccess) {
              fullAccess = false;
            }
          }
          const groupedByMarkedAndProvider = this.dataCentersService.getGroupedBuildingsByMarket(userMap.datacenters, true);
          const providers = this.dataCentersService.groupDatacentersByProvider(groupedByMarkedAndProvider.flatMap(m => m.children), userMap.datacenters);
          return mapConfigActions.loadUserMapSuccess({
            userMap: userMap,
            providers: providers,
            readOnly: !fullAccess && userMap.userId != this.appStateService.user.userId
          });
        }),
        catchError((error: any) => of(mapConfigActions.loadUserMapError({msg: "error"})))
      ))
    )
  );

}
