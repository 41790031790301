import {MapboxUser} from "../../model/mapbox-user";
import {Action, createReducer, on} from "@ngrx/store";
import {authActions} from "../actions";
import {AppError} from "../../model/app-error";

export interface State {
  loggedUser: MapboxUser,
  loggedOut: boolean
  authError: AppError
}

export const initialState: State = {
  loggedUser: null,
  loggedOut: false,
  authError: null
}

const authReducer = createReducer(initialState,
  on(authActions.login, (state, action) => {
    return {
      ...initialState,
      loggedUser: action.user,
      loggedOut: false
    };
  }),
  on(authActions.logout, (state, action) => {
    return {
      ...initialState,
      loggedUser: null,
      loggedOut: true
    };
  }),
  on(authActions.authError, (state, action) => {
    return {
      ...initialState,
      authError: action.error
    };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return authReducer(state, action);
}


