import {Injectable} from '@angular/core';
import {UserMap} from "../model/user-map";
import {Nestable} from "../model/summary";
import {Datacenter} from "../model/datacenter";

@Injectable({
  providedIn: 'root'
})
export class MapConfigStateService {
  userMap: UserMap;
  selectedProviders: Nestable[] = [];
  dataCenters: Datacenter[] = [];

  constructor() {
  }
}
