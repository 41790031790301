import * as mapboxgl from "mapbox-gl";
import {MapLine, MapPolygon} from "../../model/summary";
import {GeoInfo} from "../../model/geo-info";
import {Action, createReducer, on} from "@ngrx/store";
import {geoActions} from "../actions";

export interface State {
  countries: GeoInfo[],
  states: GeoInfo[],
  cities: GeoInfo[],
  geoInfo: GeoInfo[],
  selectedCountry: GeoInfo,
  selectedState: GeoInfo,
  selectedCity: GeoInfo,
  selectedGeoInfo: GeoInfo,
  location: GeoInfo
}

export const initialState: State = {
  geoInfo: [],
  countries: [],
  states: [],
  cities: [],
  selectedCountry: null,
  selectedState: null,
  selectedGeoInfo: null,
  selectedCity: null,
  location: null
}

const geoReducer = createReducer(initialState,
  on(geoActions.loadCountriesSuccess, (state, action) => {
    return {
      ...initialState,
      countries: action.countries
    };
  }),

  on(geoActions.queryGeoSuccess, (state, action) => {
    if (action.geoInfo.length == 1 && action.geoInfo[0].id == "-1") {
      return {
        ...state,
        location: action.geoInfo[0],
        geoInfo: []
      };
    } else {
      return {
        ...state,
        geoInfo: action.geoInfo
      };
    }
  }),
  on(geoActions.queryGeoFailure, (state, action) => {
    return {
      ...state,
      geoInfo: []
    };
  }),

  on(geoActions.getLocationSuccess, (state, action) => {
    return {
      ...state,
      location: action.geoInfo
    };
  }),

  on(geoActions.loadStatesSuccess, (state, action) => {
    return {
      ...state,
      selectedCountry: action.country,
      selectedCity: null,
      selectedState: null,
      states: action.states,
      cities: []
    };
  }),

  on(geoActions.loadCitiesSuccess, (state, action) => {
    return {
      ...state,
      selectedState: action.state,
      selectedCity: null,
      cities: action.cities
    };
  }),

  on(geoActions.citySelected, (state, action) => {
    return {
      ...state,
      selectedCity: action.city
    };
  }),
);

export function reducer(state: State | undefined, action: Action): any {
  return geoReducer(state, action);
}

