import {Injectable} from '@angular/core';
import {Nestable} from "../model/summary";
import {MapboxUser} from "../model/mapbox-user";
import {AuthSelector} from "../ngrx/selectors/auth.selector";
import {tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  selectedProviders: Nestable[] = [];
  user: MapboxUser = null;

  constructor() {

  }
}
