import {createAction, props} from "@ngrx/store";
import {GeoInfo} from "../../model/geo-info";

export const LOAD_COUNTRIES = '[Geo Filters] Load Countries';
export const LOAD_COUNTRIES_SUCCESS = '[Geo Filters] Load Countries Success';
export const LOAD_COUNTRIES_FAILURE = '[Geo Filters] Load Countries Failure';

export const LOAD_STATES_SUCCESS = '[Geo Filters] Load States Success';
export const LOAD_STATES_FAILURE = '[Geo Filters] Load States Failure';

export const LOAD_CITIES_SUCCESS = '[Geo Filters] Load Cities Success';
export const LOAD_CITIES_FAILURE = '[Geo Filters] Load Cities Failure';

export const COUNTRY_SELECTED = '[Geo Filters] Country Selected';
export const STATE_SELECTED = '[Geo Filters] State Selected';
export const CITY_SELECTED = '[Geo Filters] City Selected';

export const QUERY_GEO = '[Geo Filters] Query Geo';
export const QUERY_GEO_SUCCESS = '[Geo Filters] Query Geo Success';
export const QUERY_GEO_FAILURE = '[Geo Filters] Query Geo Failure';

export const GET_LOCATION = '[Geo Filters] Get Location';
export const GET_LOCATION_SUCCESS = '[Geo Filters] Get Location Success';
export const GET_LOCATION_FAILURE = '[Geo Filters] Get Location Failure';


export const CENTER_MAP = '[Geo Filters] Center Map';

export const loadCountries = createAction(
  LOAD_COUNTRIES
);

export const loadCountriesSuccess = createAction(
  LOAD_COUNTRIES_SUCCESS,
  props<{ countries: GeoInfo[] }>()
);

export const loadCountriesFailure = createAction(
  LOAD_COUNTRIES_FAILURE,
  props<{ msg: string }>()
);

export const loadStatesSuccess = createAction(
  LOAD_STATES_SUCCESS,
  props<{ country: GeoInfo, states: GeoInfo[] }>()
);

export const loadStatesFailure = createAction(
  LOAD_STATES_FAILURE,
  props<{ msg: string }>()
);

export const loadCitiesSuccess = createAction(
  LOAD_CITIES_SUCCESS,
  props<{ country: GeoInfo, state: GeoInfo, cities: GeoInfo[] }>()
);

export const loadCitiesFailure = createAction(
  LOAD_CITIES_FAILURE,
  props<{ msg: string }>()
);

export const countrySelected = createAction(
  COUNTRY_SELECTED,
  props<{ country: GeoInfo }>()
);

export const stateSelected = createAction(
  STATE_SELECTED,
  props<{ state: GeoInfo }>()
);

export const citySelected = createAction(
  CITY_SELECTED,
  props<{ city: GeoInfo }>()
);

export const centerMap = createAction(
  CENTER_MAP,
  props<{ info: GeoInfo }>()
);

export const queryGeo = createAction(
  QUERY_GEO,
  props<{ query: string }>()
);

export const queryGeoSuccess = createAction(
  QUERY_GEO_SUCCESS,
  props<{ geoInfo: GeoInfo[] }>()
);

export const queryGeoFailure = createAction(
  QUERY_GEO_FAILURE,
  props<{ msg: string }>()
);

export const getLocation = createAction(
  GET_LOCATION,
  props<{ id: string }>()
);

export const getLocationSuccess = createAction(
  GET_LOCATION_SUCCESS,
  props<{ geoInfo: GeoInfo }>()
);

export const getLocationFailure = createAction(
  GET_LOCATION_FAILURE,
  props<{ msg: string }>()
);
