import { Component, ViewEncapsulation, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { FiberNetworkMapLayer } from '../../core/model/network';
import { FiberNetworkService } from '../../core/service/fiber-network.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { MapLayer } from '../../core/model/map-layer.interface';

@Component({
  selector: 'app-networks-table',
  templateUrl: './networks-table.component.html',
  styleUrls: ['./networks-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService],
})
export class NetworksTableComponent implements OnInit, OnDestroy {
  @Input() onlyVisible: boolean = true;
  @Output() onLayerClick = new EventEmitter<MapLayer>();
  
  networks: FiberNetworkMapLayer[] = [];
  networksOrig: FiberNetworkMapLayer[] = [];
  networksInView: FiberNetworkMapLayer[] = [];
  selectedNetworks: FiberNetworkMapLayer[] = [];
  currentFilter: string = "";
  changingColorNetwork: string = null;
  changingColor: string = null;
  
  private destroy$ = new Subject<boolean>();

  constructor(
    private fiberNetworkService: FiberNetworkService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listFiberNetworks();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  listFiberNetworks(): void {
    this.fiberNetworkService.listFiberNetworks().pipe(
      tap(networks => {
        this.networks = networks;
        this.networksOrig = _.cloneDeep(networks);
        this.networksInView = _.cloneDeep(networks);
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      error: (error) => {
        console.error('Error loading fiber networks:', error);
      }
    });
  }

  filter(dt, event) {}

  handleSelectedAfterFilter() {}

  handleNetworksChange(networks: FiberNetworkMapLayer[]) {}

  overNetwork(network: FiberNetworkMapLayer) {}

  leaveNetwork(network: FiberNetworkMapLayer) {}

  selectedNetworksChanged() {}

  hideNetwork() {}

  colorChanged(event, network) {}

  onShow() {}

  onHide() {}

  toggleAll() {}

  createNetwork() {
    // TODO: Implement network creation logic
    this.messageService.add({
      severity: 'info',
      summary: 'Create Network',
      detail: 'Functionality under development'
    });
  }

  viewNetwork() {
    if (this.selectedNetworks.length) {
      // TODO: Implementar lógica para visualizar network
      this.messageService.add({
        severity: 'info',
        summary: 'View Network',
        detail: `Viewing network: ${this.selectedNetworks[0].supplierName}`
      });
    }
  }

  deleteNetwork(networkToDelete: FiberNetworkMapLayer) {    
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the network <b>${networkToDelete.supplierName}</b>?`,
      header: 'Confirm Deletion',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.fiberNetworkService.deleteFiberNetwork(networkToDelete.mapId)
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Network successfully deleted'
              });
              this.listFiberNetworks();
              this.selectedNetworks = [];
            },
            error: (error) => {
              console.error('Error deleting network:', error);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the network'
              });
            }
          });
      },
      reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Cancelled',
          detail: 'Deletion cancelled'
        });
      }
    });
  }

  onNetworkClick(network: FiberNetworkMapLayer) {
    const mapLayer: MapLayer = {
      id: network.supplierName,
      mapId: network.mapId,
      supplierName: network.supplierName,
      type: 'network',
      properties: {
        serviceType: network.serviceType,
        regions: network.regions,
        underDNA: network.underDNA
      }
    };
    
    this.onLayerClick.emit(mapLayer);
  }
}
