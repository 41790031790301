import * as _ from "lodash";
import * as turf from "@turf/turf";
import {Feature} from "@turf/turf";
import {MapLine} from "../../../../model/summary";
import * as mapboxgl from "mapbox-gl";
import {DISTANCE_LABEL_SOURCE_TYPE} from "../../../../consts/map.constants";
import {State} from "../../map-config.reducer";
import {DistanceUnits} from "../../../../model/user-map";
import { FIBER_OPTIC_SPEED } from "../../../../consts/map.constants";

export const showLineDrawings = (state: State, action): State => {
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };
  drawFeaturesCollection.features = _.cloneDeep(state.drawingFeatures.features);
  
  for (const mapLine of state.mapLines) {
    const line = turf.lineString([mapLine.pointA, mapLine.pointB]);
    const distanceKm = turf.length(line, {units: 'kilometers'});
    const latencyUs = (distanceKm / FIBER_OPTIC_SPEED).toFixed(1);
    
    const distancelabel = `${numberWithCommas(turf.length(line, {units: state.units}).toFixed(0))} ` +
      `${state.units == DistanceUnits.MILES ? 'miles' : 'kms'}\n` +
      `Latency: ${latencyUs}μs`;

    const lineFeature: Feature = {
      type: "Feature",
      id: mapLine.auxId,
      geometry: {
        type: "LineString",
        coordinates: [mapLine.pointA, mapLine.pointB]
      },
      properties: {
        label: distancelabel
      }
    };
    drawFeaturesCollection.features.push(lineFeature as any);
  }
  
  return {
    ...state,
    displayLines: true,
    mapLines: [],
    drawingFeatures: drawFeaturesCollection,
  };
}

export const hideLineDrawings = (state: State, action): State => {
  const mapLines: MapLine[] = [];
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };
  
  for (const feature of action.drawControlFeatureCollection.features) {
    if (feature.geometry.type == "LineString") {
      const geometry = feature.geometry as any;
      const line = turf.lineString([
        [geometry.coordinates[0][0], geometry.coordinates[0][1]],
        [geometry.coordinates[1][0], geometry.coordinates[1][1]]
      ]);
      const distanceKm = turf.length(line, {units: 'kilometers'});
      const latencyUs = (distanceKm / FIBER_OPTIC_SPEED).toFixed(1);
      
      const mapLine: MapLine = {
        auxId: feature.id.toString(),
        label: feature.properties['label'],
        pointA: [geometry.coordinates[0][0], geometry.coordinates[0][1]],
        pointB: [geometry.coordinates[1][0], geometry.coordinates[1][1]],
      };
      mapLines.push(mapLine);
    } else {
      drawFeaturesCollection.features.push(feature);
    }
  }
  
  return {
    ...state,
    displayLines: false,
    mapLines: mapLines,
    drawingFeatures: drawFeaturesCollection,
  };
}

export const updateDistanceUnits = (state: State, action): State => {
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };

  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: _.cloneDeep(state.mapSource.data)
  };

  for (let feature of state.drawingFeatures.features) {
    if (feature.geometry.type == "LineString") {
      let line = turf.lineString((feature.geometry as any).coordinates);
      const distanceKm = turf.length(line, {units: 'kilometers'});
      const latencyUs = (distanceKm / FIBER_OPTIC_SPEED).toFixed(1);
      
      const distancelabel = `${numberWithCommas(turf.length(line, {units: action.units}).toFixed(0))} ` +
        `${action.units == DistanceUnits.MILES ? 'miles' : 'kms'}\n` +
        `Latency: ${latencyUs}μs`;
        
      const newLine = _.cloneDeep(feature);
      newLine.properties['label'] = distancelabel;
      drawFeaturesCollection.features.push(newLine);
    } else {
      drawFeaturesCollection.features.push(feature);
    }
  }

  for (let feature of (mapSource.data as any).features) {
    if (feature.geometry.type == "LineString") {
      let line = turf.lineString((feature.geometry as any).coordinates);
      const distanceKm = turf.length(line, {units: 'kilometers'});
      const latencyUs = (distanceKm / FIBER_OPTIC_SPEED).toFixed(1);
      
      const distancelabel = `${numberWithCommas(turf.length(line, {units: action.units}).toFixed(0))} ` +
        `${action.units == DistanceUnits.MILES ? 'miles' : 'kms'}\n` +
        `Latency: ${latencyUs}μs`;
      feature.properties['label'] = distancelabel;
    }
  }

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    mapSource: mapSource,
    units: action.units
  };
}

export const updateDistanceLabel = (state: State, action): State => {
  var line = turf.lineString((action.feature.geometry as any).coordinates);
  const distanceKm = turf.length(line, {units: 'kilometers'});
  const latencyUs = (distanceKm / FIBER_OPTIC_SPEED).toFixed(1);
  
  const distancelabel = `${numberWithCommas(turf.length(line, {units: state.units}).toFixed(0))} ` +
    `${state.units == DistanceUnits.MILES ? 'miles' : 'kms'}\n` +
    `Latency: ${latencyUs}μs`;

  const exFeature = action.drawControlFeatureCollection.features.filter(f => f.id == action.feature.id)[0];

  const lineFeature: Feature = {
    id: exFeature.id,
    type: "Feature", 
    geometry: {
      type: "LineString",
      coordinates: (exFeature.geometry as any).coordinates
    },
    properties: {
      label: distancelabel
    }
  };

  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };
  drawFeaturesCollection.features = _.cloneDeep(action.drawControlFeatureCollection.features.filter(f => f.id != action.feature.id));
  drawFeaturesCollection.features.push(lineFeature as any);

  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: _.cloneDeep(state.mapSource.data)
  };

  const existingFeature = (mapSource.data as any).features.filter(
    f => f.properties["id"] && action.feature.id == f.properties["id"]);
  if (existingFeature.length) {
    existingFeature[0].properties['label'] = distancelabel;
    existingFeature[0].geometry = action.feature.geometry;
  } else {
    const pointFeature: Feature = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: (action.feature.geometry as any).coordinates
      },
      properties: {
        label: distancelabel,
        id: action.feature.id,
        type: DISTANCE_LABEL_SOURCE_TYPE
      }
    };
    (mapSource.data as any).features.push(pointFeature);
  }

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    mapSource: mapSource
  };
}

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
