import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AppStateService} from "./app-state.service";
import {AuthSelector} from "../ngrx/selectors/auth.selector";
import {map, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {UserMap} from "../model/user-map";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private appStateService: AppStateService, private authSelector: AuthSelector) {
  }

  public getUsers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/users`, {responseType: 'json'});
  }
}
