import { Component, ViewEncapsulation, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { PowerMapLayer } from '../../core/model/power';
import { PowerService } from '../../core/service/power.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { MapLayer } from '../../core/model/map-layer.interface';

@Component({
  selector: 'app-power-table',
  templateUrl: './power-table.component.html',
  styleUrls: ['./power-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService],
})
export class PowerTableComponent implements OnInit, OnDestroy {
  @Input() onlyVisible: boolean = true;
  @Output() onLayerClick = new EventEmitter<MapLayer>();
  
  powers: PowerMapLayer[] = [];
  powersOrig: PowerMapLayer[] = [];
  powersInView: PowerMapLayer[] = [];
  selectedPowers: PowerMapLayer[] = [];
  currentFilter: string = "";
  changingColorPower: string = null;
  changingColor: string = null;
  
  private destroy$ = new Subject<boolean>();

  constructor(
    private powerService: PowerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listPowers();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  listPowers(): void {
    this.powerService.listPowers().pipe(
      tap(powers => {
        this.powers = powers;
        this.powersOrig = _.cloneDeep(powers);
        this.powersInView = _.cloneDeep(powers);
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      error: (error) => {
        console.error('Error loading powers:', error);
      }
    });
  }

  filter(dt, event) {}

  handleSelectedAfterFilter() {}

  handlePowersChange(powers: PowerMapLayer[]) {}

  overPower(power: PowerMapLayer) {}

  leavePower(power: PowerMapLayer) {}

  selectedPowersChanged() {}

  hidePower() {}

  colorChanged(event, power) {}

  onShow() {}

  onHide() {}

  toggleAll() {}

  createPower() {
    this.messageService.add({
      severity: 'info',
      summary: 'Create Power',
      detail: 'Functionality under development'
    });
  }

  viewPower() {
    if (this.selectedPowers.length) {
      this.messageService.add({
        severity: 'info',
        summary: 'View Power',
        detail: `Viewing power: ${this.selectedPowers[0].supplierName}`
      });
    }
  }

  deletePower(powerToDelete: PowerMapLayer) {    
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the power <b>${powerToDelete.supplierName}</b>?`,
      header: 'Confirm Deletion',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.powerService.deletePower(powerToDelete.mapId)
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Power successfully deleted'
              });
              this.listPowers();
              this.selectedPowers = [];
            },
            error: (error) => {
              console.error('Error deleting power:', error);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the power'
              });
            }
          });
      },
      reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Cancelled',
          detail: 'Deletion cancelled'
        });
      }
    });
  }

  onPowerClick(power: PowerMapLayer) {
    const mapLayer: MapLayer = {
      id: power.supplierName,
      mapId: power.mapId,
      supplierName: power.supplierName,
      type: 'power',
      properties: {
        serviceType: power.serviceType,
        regions: power.regions,
        underDNA: power.underDNA
      }
    };
    
    this.onLayerClick.emit(mapLayer);
  }
}
