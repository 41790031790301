<div class="az-sidebar sidebar-inner-content">
  <div class="az-layers">
    <ng-container *ngFor="let group of groupedLayers$ | async">
      <div class="az-group">
        <div class="group-header">
          <span class="group-title">{{group.azId}}</span>
        </div>
        <div class="group-content">
          <div *ngFor="let layer of group.layers" class="az-layer">
            <div class="layer-header">
              <div class="layer-info">
                <p-colorPicker 
                  [appendTo]="'body'" 
                  [(ngModel)]="layer.color"
                  (onHide)="onHide()"
                  (onChange)="colorChanged($event, layer)">
                </p-colorPicker>
                <span class="layer-name">{{layer.provider}}</span>
              </div>
              <a (click)="toggleAZVisibility(layer)"
                 style="cursor: pointer; text-decoration: none; margin-top: 3px"
                 [class]="layer.isVisible ? 'pi pi-eye' : 'pi pi-eye-slash'">
              </a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>