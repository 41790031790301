import {MapboxUser} from "../../model/mapbox-user";
import {Action, createReducer, on} from "@ngrx/store";
import {usersActions} from '../actions/index'
import {getUsers, getUsersSuccess} from "../actions/users.actions";

export interface State {
  existingUsers: MapboxUser[]
}

export const initialState: State = {
  existingUsers: []
}
const usersReducer = createReducer(
  initialState,
  on(usersActions.getUsersSuccess, (state, action) => {
    return {
      ...initialState,
      existingUsers: action.users
    };
  }));

export function reducer(state: State | undefined, action: Action): any {
  return usersReducer(state, action);
}
