import {Datacenter, DatacenterBasicInfo} from "../model/datacenter";
import {Nestable} from "../model/summary";
import * as _ from "lodash";
import {Feature} from "@turf/turf";
import * as turf from "@turf/turf";
import {Units} from "@turf/helpers";

export const buildNewBasicDatacenter = (basicData: DatacenterBasicInfo): Datacenter => {
  const datacenter: Datacenter = {
    address: "",
    area: "",
    building: "",
    buildingAreaLive: 0,
    buildingAreaPlanned: 0,
    buildingAreaTotal: 0,
    buildingAreaUC: 0,
    country: "",
    dataHallLive: 0,
    dataHallPlanned: 0,
    dataHallTotal: 0,
    dataHallUC: 0,
    firstYearOp: "",
    lat: 0,
    lon: 0,
    mapId: "",
    market: "",
    mwLive: 0,
    mwPlanned: 0,
    mwTotal: 0,
    mwUC: 0,
    provider: "",
    ...basicData
  };

  return datacenter;
}

export const removeFromDataList = (datalist: DatacenterBasicInfo[], toRemove: DatacenterBasicInfo) => {
  let newDatalist: DatacenterBasicInfo[];
  if (toRemove.locationId) {
    newDatalist = datalist.filter(dc => dc.locationId !== toRemove.locationId);
  } else {
    newDatalist = datalist.filter(dc => dc.tempId !== toRemove.tempId);
  }
  return newDatalist;
}

export const removeFromProvidersNestable = (providers: Nestable[], toRemove: DatacenterBasicInfo) => {
  let newProvidersList: Nestable[] = [];
  for (let provider of providers) {
    provider.children = removeFromDataList(provider.children as DatacenterBasicInfo[], toRemove) as Datacenter[];
    if (provider.children.length) {
      newProvidersList.push(provider);
    }
  }
  return newProvidersList;
}

export const getFromDataList = (datalist: Datacenter[], toGet: DatacenterBasicInfo) => {
  let index = -1;
  if (toGet.locationId) {
    index = datalist.findIndex(dc => dc.locationId === toGet.locationId);
  } else {
    index = datalist.findIndex(dc => dc.tempId === toGet.tempId);
  }
  if (index !== -1) {
    return datalist[index];
  }
  return null;
}

export const isDataCenterEquals = (datacenter: Datacenter, basic: Datacenter) => {
  if (basic.locationId) {
    return datacenter.locationId === basic.locationId;
  } else {
    return datacenter.tempId === basic.tempId;
  }
}

export const modifyProvidersNestable = (providers: Nestable[], modified: DatacenterBasicInfo) => {
  let newProvidersList: Nestable[] = providers;
  for (let provider of newProvidersList) {
    let existingDatacenter = getFromDataList(provider.children as Datacenter[], modified);
    if (existingDatacenter) {
      existingDatacenter.mwLive = modified.mwLive;
      existingDatacenter.mwUC = modified.mwUC;
      existingDatacenter.mwPlanned = modified.mwPlanned;
      existingDatacenter.dataHallLive = modified.dataHallLive;
      existingDatacenter.buildingAreaTotal = modified.buildingAreaTotal;
      existingDatacenter.mapId = modified.mapId;
    }

  }
  return newProvidersList;
}

export const getMarkersInsidePolygon = (selectedDatacenterConfig: DatacenterBasicInfo[], polygonFeature: Feature) => {
  const markersFeatures = selectedDatacenterConfig.map(dc => {
    const markerPoint: Feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [dc.lon, dc.lat]
      },
      properties: {
        locationId: dc.locationId,
        tempId: dc.tempId
      }
    };
    return markerPoint;
  });

  const markersFC: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };


  markersFC.features = markersFeatures as any;
  const polygon = (polygonFeature.geometry as any).coordinates;
  //let markers = turf.points(markersPoints);
  let withinPolygon = turf.polygon(polygon);
  if (polygonFeature.properties && polygonFeature.properties["circleRadius"] && polygonFeature.properties["circleRadius"] != -1) {
    let center = polygon[0][0];
    let radius = polygonFeature.properties["circleRadius"];
    var options = {units: "kilometers" as any};
    withinPolygon = turf.circle(center, radius, options);
  }
  var markersInside = turf.pointsWithinPolygon(markersFC as any, withinPolygon);
  const datacentersInside: DatacenterBasicInfo[] = [];
  markersInside.features.forEach(markerInside => {
    datacentersInside.push(getDatacenterByFeature(selectedDatacenterConfig, markerInside))
  });
  return datacentersInside;
}

export const getDatacenterByFeature = (selectedDatacenterConfig: DatacenterBasicInfo[], feature: Feature) => {
  if (feature.properties["locationId"]) {
    return selectedDatacenterConfig.filter(dc => dc.locationId == feature.properties["locationId"])[0];
  } else {
    return selectedDatacenterConfig.filter(dc => dc.tempId == feature.properties["tempId"])[0];
  }
}

export const isPointWithinAnyPolygon = (clickedPoint, featureCollection) => {
  const markerPoint: Feature = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [clickedPoint.lng, clickedPoint.lat]
    },
    properties: {}
  };
  const markersFC: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: [markerPoint as any]
  };
  for (let polygon of featureCollection.features) {
    if (polygon.geometry.type != "LineString") {
      const p = (polygon.geometry as any).coordinates;
      let withinPolygon = turf.polygon(p);
      if (polygon.properties && polygon.properties["circleRadius"] && polygon.properties["circleRadius"] != -1) {
        let center = p[0][0];
        let radius = polygon.properties["circleRadius"];
        let options = {units: "kilometers" as any};
        withinPolygon = turf.circle(center, radius, options);
      }
      let fc = turf.pointsWithinPolygon(markersFC as any, withinPolygon);
      if (fc.features.length) {
        return true;
      }
    }
  }
  return false;
}
