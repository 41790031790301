import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Datacenter} from "../../core/model/datacenter";

@Component({
  selector: 'app-map-data-table',
  templateUrl: './map-data-table.component.html',
  styleUrls: ['./map-data-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapDataTableComponent {
  @Input()
  data: Datacenter[] = [];
  @Input()
  header: string = "";
}
