import {Injectable} from "@angular/core";
import {createFeatureSelector, createSelector, Store} from "@ngrx/store";
import * as fromGeo from "../reducers/geo.reducer";
import {Observable} from "rxjs";
import {GeoInfo} from "../../model/geo-info";

@Injectable()
export class GeoSelector {
  constructor(private readonly store: Store) {
  }

  getGeoState = createFeatureSelector<fromGeo.State>('geo');

  getCountries(): Observable<GeoInfo[]> {
    return this.store.select((createSelector(
      this.getGeoState,
      (state: fromGeo.State) => state.countries
    )));
  };

  getStates(): Observable<GeoInfo[]> {
    return this.store.select((createSelector(
      this.getGeoState,
      (state: fromGeo.State) => state.states
    )));
  };

  getCities(): Observable<GeoInfo[]> {
    return this.store.select((createSelector(
      this.getGeoState,
      (state: fromGeo.State) => state.cities
    )));
  };

  getSelectedGeo(): Observable<GeoInfo[]> {
    return this.store.select((createSelector(
      this.getGeoState,
      (state: fromGeo.State) => [state.selectedCountry, state.selectedState, state.selectedCity].filter(i => i)
    )));
  };

  getGeoInfo(): Observable<GeoInfo[]> {
    return this.store.select((createSelector(
      this.getGeoState,
      (state: fromGeo.State) => state.geoInfo
    )));
  };

  getLocation(): Observable<GeoInfo> {
    return this.store.select((createSelector(
      this.getGeoState,
      (state: fromGeo.State) => state.location
    )));
  };


}
