import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {MapConfigStateService} from "../../service/map-config-state.service";
import {catchError, exhaustMap, map, of, withLatestFrom} from "rxjs";
import {mapFilesActions} from "../actions"
import {Injectable} from "@angular/core";
import {MapFileService} from "../../service/map-file.service";
import {State as mapConfigState} from "../reducers/map-config.reducer";
import * as _ from "lodash";
import {MapFile} from "../../model/map-file";
import {DEFAULT_TILES_COLOR} from "../../consts/file.constants";
import {FileMetadata} from "../../model/file-metadata";

@Injectable()
export class MapFilesEffects {
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    public mapConfigState: MapConfigStateService,
    private mapFileService: MapFileService
  ) {
  }

  loadMapFiles = createEffect(() =>
    this.actions$.pipe(
      ofType(mapFilesActions.loadFiles),
      withLatestFrom(this.store),
      exhaustMap(([action, store]) => this.mapFileService.getMapFiles().pipe(
        map(response => {
          const currentMapConfigState = (store as any).mapConfig as mapConfigState;
          if (currentMapConfigState.userMap && currentMapConfigState.userMap.files) {
            response.forEach((mapFile: MapFile) => {
              mapFile.color = DEFAULT_TILES_COLOR;
              if (currentMapConfigState.userMap.files.filter(umf => umf.fileId == mapFile.fileId).length) {
                mapFile.selected = true;
                mapFile.color = currentMapConfigState.userMap.files.filter(umf => umf.fileId == mapFile.fileId)[0].color.toString();
              }
            });
          }
          return mapFilesActions.loadFilesSuccess({mapFiles: response as any})
        }),
        catchError((error: any) => of(mapFilesActions.loadFilesFailure({msg: "error"})))
      )))
  );

  shareMapFile = createEffect(() =>
    this.actions$.pipe(
      ofType(mapFilesActions.shareMapFile),
      exhaustMap(action =>
        this.mapFileService.shareMapFile(action.fileId, action.userIds).pipe(
          map(() => {
            return mapFilesActions.shareMapFileSuccess();
          }),
          catchError((error) => of(mapFilesActions.shareMapFileFailure({error: 'Error sharing file'})))
        )
      )
    )
  );

  getMapFileMetadata = createEffect(() =>
    this.actions$.pipe(
      ofType(mapFilesActions.getFileMetadata),
      exhaustMap(action =>
        this.mapFileService.getMapFileMetadata(action.tilesetId).pipe(
          map((response) => {
            return mapFilesActions.getFileMetadataSuccess({metadata: response as FileMetadata});
          }),
          catchError((error) => of(mapFilesActions.loadFilesFailure({msg: 'Error getting file metadata'})))
        )
      )
    )
  );
}
