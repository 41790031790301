import * as _ from "lodash";
import {
  buildNewBasicDatacenter,
  getMarkersInsidePolygon, modifyProvidersNestable,
  removeFromDataList,
  removeFromProvidersNestable
} from "../../../../util/transform.util";
import {Datacenter, DatacenterBasicInfo} from "../../../../model/datacenter";
import {v4 as uuidv4} from 'uuid';
import {State} from "../../map-config.reducer";
import {Nestable} from "../../../../model/summary";
import {updateInfoSquare} from "./map.util";

export const toggleDatacenters = (state: State, action): State => {
  const exFeature = state.drawingFeatures.features.filter(f => f.id == action.auxId)[0];
  const colnedExFeature = _.cloneDeep(exFeature);
  colnedExFeature.properties['hideDatacenters'] = action.hideDatacenters

  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: _.cloneDeep(state.drawingFeatures.features.filter(f => f.id != action.auxId))
  };
  drawFeaturesCollection.features.push(colnedExFeature as any);

  const datacentersInside = getMarkersInsidePolygon(_.cloneDeep(state.datacenters.filter(dc => dc.selected)), colnedExFeature as any)

  const dataCenterConfig = _.cloneDeep(state.datacenters);
  dataCenterConfig.filter(dc => dc.selected).forEach(d => {
    let isInside = false;
    if (d.locationId) {
      isInside = datacentersInside.some(f => f.locationId == d.locationId)
    } else {
      isInside = datacentersInside.some(f => f.tempId == d.tempId)
    }

    if (isInside) {
      if (!action.hideDatacenters) {
        if (!state.providers.filter(p => p.label == d.provider && p.isProviderHidden).length) {
          d.isHidden = action.hideDatacenters;
        }
      } else {
        d.isHidden = action.hideDatacenters;
      }
    }


  });

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    datacenters: dataCenterConfig,
  };
}

export const addedDataCenter = (state: State, action): State => {
  const newDataCenter: DatacenterBasicInfo = _.cloneDeep(action.datacenterBasicInfo);
  newDataCenter.tempId = uuidv4();
  newDataCenter.selected = true;
  const newDatacenterFull: Datacenter = buildNewBasicDatacenter(newDataCenter);

  const fullDataCenterConfig = _.cloneDeep(state.datacenters);
  fullDataCenterConfig.push(newDatacenterFull);
  return {
    ...state,
    existingMarkets: fullDataCenterConfig.map(d => d.market).filter(uniqueFilter),
    existingProviders: fullDataCenterConfig.map(d => d.provider).filter(uniqueFilter),
    existingBuildingTenants: fullDataCenterConfig.flatMap(d => d.buildingTenant).filter(uniqueFilter),
    existingAvailabilityZoneIds: fullDataCenterConfig.map(d => d.availabilityZoneId).filter(uniqueFilter),
    existingDataSources: fullDataCenterConfig.flatMap(d => d.dataSource || []).filter(uniqueFilter),
    datacenters: fullDataCenterConfig
  };
}

export const changeDatacenterColor = (state: State, action): State => {
  const fullDataCenterConfig = _.cloneDeep(state.datacenters);
  fullDataCenterConfig.forEach(dc => {
    if (dc.provider == action.provider) {
      dc.color = action.color;
    }
  });

  return {
    ...state,
    existingMarkets: fullDataCenterConfig.map(d => d.market).filter(uniqueFilter),
    existingProviders: fullDataCenterConfig.map(d => d.provider).filter(uniqueFilter),
    existingBuildingTenants: fullDataCenterConfig.flatMap(d => d.buildingTenant).filter(uniqueFilter),
    existingAvailabilityZoneIds: fullDataCenterConfig.map(d => d.availabilityZoneId).filter(uniqueFilter),
    datacenters: fullDataCenterConfig
  };
}

export const removedDataCenter = (state: State, action): State => {
  const toRemove: DatacenterBasicInfo = _.cloneDeep(action.datacenterBasicInfo);
  toRemove.selected = false;

  let fullDataCenterConfig = _.cloneDeep(state.datacenters);
  fullDataCenterConfig = removeFromDataList(fullDataCenterConfig, toRemove) as Datacenter[];
  fullDataCenterConfig.push(toRemove as Datacenter);

  let providers = state.providers;
  let selectedProviders = state.selectedProviders;

  if (toRemove.locationId) {
    providers = _.cloneDeep(providers);
    selectedProviders = _.cloneDeep(selectedProviders);
    providers = removeFromProvidersNestable(providers, toRemove);
    selectedProviders = removeFromProvidersNestable(selectedProviders, toRemove);
  }
  let source = updateInfoSquare(state.mapSource, state.drawingFeatures, fullDataCenterConfig);
  return {
    ...state,
    mapSource: source,
    existingMarkets: fullDataCenterConfig.map(d => d.market).filter(uniqueFilter),
    existingProviders: fullDataCenterConfig.map(d => d.provider).filter(uniqueFilter),
    existingBuildingTenants: fullDataCenterConfig.flatMap(d => d.buildingTenant).filter(uniqueFilter),
    existingAvailabilityZoneIds: fullDataCenterConfig.map(d => d.availabilityZoneId).filter(uniqueFilter),
    existingDataSources: fullDataCenterConfig.flatMap(d => d.dataSource || []).filter(uniqueFilter),
    datacenters: fullDataCenterConfig,
    providers: providers,
    selectedProviders: selectedProviders
  };
}

export const modifiedDataCenter = (state: State, action): State => {
  const modified: DatacenterBasicInfo = _.cloneDeep(action.datacenterBasicInfo);
  modified.selected = true;

  let fullDataCenterConfig = _.cloneDeep(state.datacenters);
  fullDataCenterConfig = removeFromDataList(fullDataCenterConfig, modified) as Datacenter[];
  fullDataCenterConfig.push(modified as Datacenter)

  let providers = state.providers;
  let selectedProviders = state.selectedProviders;

  if (modified.locationId) {
    providers = _.cloneDeep(providers);
    selectedProviders = _.cloneDeep(selectedProviders);
    providers = modifyProvidersNestable(providers, modified);
    selectedProviders = modifyProvidersNestable(selectedProviders, modified);
  }
  let source = updateInfoSquare(state.mapSource, state.drawingFeatures, fullDataCenterConfig);
  return {
    ...state,
    mapSource: source,
    datacenters: fullDataCenterConfig,
    providers: providers,
    selectedProviders: selectedProviders
  };
}

export const datacenterHoverChange = (state: State, action): State => {
  let dataCenterConfig = _.cloneDeep(state.datacenters);
  if (action.isProvider) {
    dataCenterConfig.filter(dc => dc.selected && dc.provider === action.value).forEach(dc => dc.highligth = action.hover);
  } else {
    dataCenterConfig.filter(dc => dc.selected && dc.locationId == action.value).forEach(dc => dc.highligth = action.hover);
  }

  return {
    ...state,
    datacenters: dataCenterConfig
  };
}

export const increaseMarkersSize = (state: State, action): State => {
  let newScale = state.scale + 0.1;
  return {
    ...state,
    scale: newScale
  };
}

export const reduceMarkersSize = (state: State, action): State => {
  let newScale = state.scale - 0.1;
  return {
    ...state,
    scale: newScale
  };
}

export const resetMarkersSize = (state: State, action): State => {
  let intScale = action.zoom;
  let newScale = state.scale;
  if (intScale <= 7 && intScale >= 6) {
    newScale = 0.7;
  } else if (intScale < 6 && intScale >= 5) {
    newScale = 0.5;
  } else if (intScale < 5) {
    newScale = 0.3;
  } else if (intScale > 6) {
    newScale = 1;
  }
  return {
    ...state,
    scaleAux: newScale,
    scale: newScale
  };
}

export const selectedProvidersChanged = (state: State, action): State => {
  const selectedProviders: Nestable[] = _.cloneDeep(action.selectedProviders);
  for (let provider of selectedProviders) {
    handleDatacenterColor(provider.children, state.mwThreshold, state.userMap);
  }
  return {
    ...state,
    selectedProviders: selectedProviders
  };
}

export const zoom = (state: State, action): State => {
  let intScale = action.zoom;
  let newScale = state.scale;
  if (newScale == state.scaleAux) {
    if (intScale <= 7 && intScale >= 6) {
      newScale = 0.7;
    } else if (intScale < 6 && intScale >= 5) {
      newScale = 0.5;
    } else if (intScale < 5) {
      newScale = 0.3;
    } else if (intScale > 6) {
      newScale = 1;
    }
    return {
      ...state,
      scaleAux: newScale,
      scale: newScale
    };
  }
  return {
    ...state
  };
}

export const changeMwThresholdValue = (state: State, action): State => {
  const datacenters: Datacenter[] = _.cloneDeep(state.datacenters);
  handleDatacenterColor(datacenters, action.value, state.userMap);
  return {
    ...state,
    datacenters: datacenters,
    mwThreshold: action.value
  };
}

export const toggleProviderVisibility = (state: State, action): State => {

  const providers: Nestable[] = action.provider;
  const existingProvider: Nestable[] = _.cloneDeep(state.providers);
  const dataCenterConfig = _.cloneDeep(state.datacenters);

  providers.forEach(provider => {
    existingProvider.filter(p => p.label == provider.label).forEach(p => {
      p.isProviderHidden = action.visible;
      dataCenterConfig.filter(dc => dc.selected && dc.provider == p.label).forEach(d => {
        d.isHidden = p.isProviderHidden;
      });
    });
  });


  return {
    ...state,
    providers: existingProvider,
    datacenters: dataCenterConfig
  };
}

export const toggleMarkersDrag = (state: State, action): State => {
  return {
    ...state,
    areMarkersBlocked: !state.areMarkersBlocked
  };
}

export const uniqueFilter = (value, index, self) => {
  if (Array.isArray(value)) {
    return index === self.findIndex(item => 
      JSON.stringify(item) === JSON.stringify(value)
    );
  }
  return self.indexOf(value) === index;
}

export const handleDatacenterColor = (datacenters, mwThreshold, userMap) => {
  for (let datacenter of datacenters) {
    if (datacenter.mwLive >= mwThreshold) {
      datacenter.isHidden = false;
    } else {
      datacenter.isHidden = true;
    }
  }
}

export const updateAZColor = (state: State, action): State => {
  const updatedDatacenters = state.datacenters.map(dc => {
    if (dc.provider === action.provider && dc.availabilityZoneId === action.azId) {
      return { ...dc, color: action.color };
    }
    return dc;
  });

  return {
    ...state,
    datacenters: updatedDatacenters
  };
}
