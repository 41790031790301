<div class="col-lg-12">
  <div class="row">
    <div *ngFor="let selecProv of selectedProviders" class="col-lg-4 detail-con">
      <p-table [scrollable]="true"
               scrollHeight="700px" [value]="selecProv.children" [rowHover]="true"
               [lazy]="false">
        <ng-template pTemplate="header">
          <tr>
            <th colspan="7" style="width:15%;">
              <div class="flex justify-content-between align-items-center">
                {{selecProv.label}}
              </div>
            </th>
          </tr>
          <tr class="header-detail">
            <th rowspan="2" style="width:10%;">
              <div class="flex justify-content-between align-items-center">
                ID
              </div>
            </th>
            <th rowspan="2" style="width:20%;">
              <div class="flex justify-content-between align-items-center">
                Building
              </div>
            </th>
            <th colspan="3" style="width:15%;">
              <div class="flex justify-content-between align-items-center">
                MW
              </div>
            </th>
            <th rowspan="2" style="width:10%;">
              <div class="flex justify-content-between align-items-center">
                Year Live
              </div>
            </th>
            <th rowspan="2" style="width:40%;">
              <div class="flex justify-content-between align-items-center">
                City
              </div>
            </th>
          </tr>
          <tr class="header-detail">
            <th style="width:15%;">
              <div class="flex justify-content-between align-items-center">
                Live
              </div>
            </th>
            <th style="width:15%;">
              <div class="flex justify-content-between align-items-center">
                U/C
              </div>
            </th>
            <th style="width:15%;">
              <div class="flex justify-content-between align-items-center">
                Plan
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-building>
          <tr (click)="goToLocation(building)" class="p-selectable-row">
            <td>
                      <span class="dot" [style.color]="building.font" [style.backgroundColor]="building.color">
                        {{building.mapId}}
                      </span>
            </td>
            <td>
              {{building.building}}
            </td>
            <td>
              {{building.mwLive}}
            </td>
            <td>
              {{building.mwUC}}
            </td>
            <td>
              {{building.mwPlanned}}
            </td>
            <td>
              {{building.firstYearOp}}
            </td>
            <td>
              {{building.market}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td>Total</td>
            <td></td>
            <td style="text-align: center">{{sumTotals(selecProv, 'mwLive')}}</td>
            <td style="text-align: center">{{sumTotals(selecProv, 'mwUC')}}</td>
            <td style="text-align: center">{{sumTotals(selecProv, 'mwPlanned')}}</td>
            <td class="text-right"></td>
            <td class="text-right"></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
