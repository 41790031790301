import {Injectable, Inject, Optional} from '@angular/core';
import {OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';


import {catchError, Observable} from "rxjs";
import {Store} from "@ngrx/store";
import {authActions} from "../ngrx/actions"
import {AppError} from "../model/app-error";
import {environment} from "../../../environments/environment";

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
    private readonly store: Store,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {
  }

  private checkUrl(url: string): boolean {
    let found = url.startsWith(environment.apiUrl);
    return !!found;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url.toLowerCase();

    if (!this.checkUrl(url)) return next.handle(req);
    let token = localStorage.getItem('access_token');
    if (token) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status == 401) {
          const error: AppError = {
            errorCode: err.error.statusCode,
            errorMessage: err.error.message,
            httpCode: err.status
          }
          this.store.dispatch(authActions.authError({error: error}));
        } else if (err.status == 403) {

        }
        return this.errorHandler.handleError(err)
      })
    );

  }

}
