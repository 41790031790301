<div class="list-container">
  <div>
    <p-table [scrollable]="true" [style]="{ width: '500px' }" [(selection)]="selectedUsers"
             scrollHeight="220px" [value]="existingUsers" [rowHover]="true"
             [lazy]="false">
      <ng-template pTemplate="header">
        <tr class="header-detail">
          <th style="width:20%;">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th style="width:50%;">
            <div class="flex justify-content-between align-items-center">
              User
            </div>
          </th>
          <th style="width:30%;">
            <div class="flex justify-content-between align-items-center">
              Full Access
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr class="p-selectable-row">
          <td>
            <p-tableCheckbox [value]="user"></p-tableCheckbox>
          </td>
          <td>
            {{user.fullName}}
          </td>
          <td>
            <p-checkbox [disabled]="!isUserSelected(user)" name="binary" [(ngModel)]="user.sharedMapFullAccess" [binary]="true" inputId="binary"></p-checkbox>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div style="margin-top: 10px;align-self: flex-end;">
    <p-button styleClass="p-button-primary primary p-button-sm" [label]="'Save'" (onClick)="share()"></p-button>
    <p-button styleClass="p-button-primary secondary p-button-sm" [label]="'Cancel'" (onClick)="cancel()"></p-button>
  </div>

</div>
