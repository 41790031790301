<div class="geo-filter-container">
  <p-autoComplete (onSelect)="onGeoInfoSelected($event)" field="name"
                  [minLength]="3"
                  [forceSelection]="false"
                  [suggestions]="geoInfo | async"
                  (completeMethod)="queryGeo($event)"
                  [placeholder]="'[latitude,longitude] or text to search...'">
    <ng-template let-geo pTemplate="item">
      <div class="flex align-items-center gap-2">
        <div><span class="row-principal-info">{{ geo.name }}</span> <span
          class="row-second-info">- {{geo.category}}</span></div>
        <div class="row">
          <div class="row-second-info">{{geo.desc}}</div>
        </div>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
