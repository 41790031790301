import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Datacenter, DatacenterBasicInfo} from "../../core/model/datacenter";
import * as _ from "lodash";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";
import {tap, take} from "rxjs/operators";
import {Store} from '@ngrx/store';
import * as mapConfigActions from '../../core/ngrx/actions/map-config.actions';

@Component({
  selector: 'app-new-data-center-popup',
  templateUrl: './new-data-center-popup.component.html',
  styleUrls: ['./new-data-center-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewDataCenterPopupComponent implements OnInit {
  latRegExp: RegExp = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/;
  lonRegExp: RegExp = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/;
  data: DatacenterBasicInfo = {
    firstYearOp: "",
    buildingAreaTotal: 0,
    country: "",
    dataHallLive: 0,
    lat: 0,
    locationId: null,
    lon: 0,
    market: "",
    mwLive: 0,
    mwPlanned: 0,
    mwUC: 0,
    provider: "",
    tempId: "",
    mapId: "",
    building: "",
    area: "",
    notes: "",
    siteType: null,
    productType: null,
    availabilityZoneId: null,
    itLoadType: null,
  };
  isModifying = false;
  isAddingProvider = false;
  isAddingMarket = false;
  providers = [];
  markets = [];
  selectedProvider = undefined;
  selectedMarket = undefined;
  productTypes = [
    { label: 'Availability Zone', value: 'Availability Zone' },
    { label: 'AI/ML', value: 'AI/ML' },
    { label: 'Local Zone', value: 'Local Zone' },
    { label: 'Enterprise', value: 'Enterprise' },
    { label: 'Internet Exchange', value: 'Internet Exchange' },
    { label: 'Edge', value: 'Edge' },
    { label: 'Wavelength Zone', value: 'Wavelength Zone' },
    { label: 'Ground Station', value: 'Ground Station' }
  ];
  availabilityZoneIds = [];
  selectedAZId: string;
  isAddingNewAZ = false;

  siteTypes = [
    { label: 'Customer self-build', value: 'Customer self-build' },
    { label: 'Opportunity', value: 'Opportunity' },
    { label: 'Edge', value: 'Edge' },
    { label: 'Competitor', value: 'Competitor' },
    { label: 'Customer Early Stage', value: 'Customer Early Stage' }
  ];

  itLoadTypes = [
    { label: 'Internal', value: 'Internal' },
    { label: 'Sellable', value: 'Sellable' },
    { label: 'Mixed (Shared)', value: 'Mixed' }
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private mapConfigSelector: MapConfigSelector,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.data = _.cloneDeep(this.config.data as DatacenterBasicInfo);
      this.isModifying = true;
      this.selectedProvider = this.data.provider;
      this.selectedMarket = this.data.market;
      if (this.data.lat) {
        this.data.lat = +this.data.lat.toFixed(6);
      }
      if (this.data.lon) {
        this.data.lon = +this.data.lon.toFixed(6);
      }
    }

    this.mapConfigSelector.getExistingProviders().pipe(
      take(1),
      tap((providers: []) => {
        let sortedProviders: [] = _.cloneDeep(providers);
        sortedProviders.sort((a: string, b: string) => a.localeCompare(b));
        this.providers = sortedProviders;
      })
    ).subscribe();

    this.mapConfigSelector.getExistingMarkets().pipe(
      take(1),
      tap((markets: []) => {
        let sortedMarkets: [] = _.cloneDeep(markets);
        sortedMarkets.sort((a: string, b: string) => a.localeCompare(b));
        this.markets = sortedMarkets;
      })
    ).subscribe();

    this.mapConfigSelector.getExistingAvailabilityZoneIds().pipe(
      take(1),
      tap((azIds: string[]) => {
        this.availabilityZoneIds = azIds.map(id => ({ label: id, value: id }));
      })
    ).subscribe();
  }

  onProductTypeChange(event: any) {
    if (event.value !== 'Availability Zone') {
      this.data.availabilityZoneId = null;
      this.selectedAZId = null;
      this.isAddingNewAZ = false;
    }
  }

  saveNewMarker() {
    if (!this.isAddingProvider) {
      this.data.provider = this.selectedProvider;
    }
    if (!this.isAddingMarket) {
      this.data.market = this.selectedMarket;
    }
    if (this.data.productType === 'Availability Zone') {
      this.data.availabilityZoneId = this.isAddingNewAZ ? this.data.availabilityZoneId : this.selectedAZId;
    }

    this.updateMarkerColor();
    this.ref.close(_.cloneDeep(this.data));
  }

  updateMarkerColor() {
    this.store.dispatch(mapConfigActions.updateMarkerColor({
      datacenter: this.data,
      newProvider: this.data.provider
    }));
  }

  cancel() {
    this.ref.close(null);
  }
}
