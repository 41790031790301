import {Injectable} from "@angular/core";
import {createFeatureSelector, createSelector, Store} from "@ngrx/store";
import * as fromMapFiles from "../reducers/map-files.reducer";
import {Observable} from "rxjs";
import {MapFile} from "../../model/map-file";

@Injectable()
export class MapFilesSelector {
  constructor(private readonly store: Store) {
  }

  getMapFilesState = createFeatureSelector<fromMapFiles.State>('mapFiles');

  getMapFiles(): Observable<MapFile[]> {
    return this.store.select((createSelector(
      this.getMapFilesState,
      (state: fromMapFiles.State) => state.mapFiles
    )));
  };

  getFiberFiles(): Observable<MapFile[]> {
    return this.store.select((createSelector(
      this.getMapFilesState,
      (state: fromMapFiles.State) => state.fiberFiles
    )));
  };

  getPowerFiles(): Observable<MapFile[]> {
    return this.store.select((createSelector(
      this.getMapFilesState,
      (state: fromMapFiles.State) => state.powerFiles
    )));
  };

  getLandFiles(): Observable<MapFile[]> {
    return this.store.select((createSelector(
      this.getMapFilesState,
      (state: fromMapFiles.State) => state.landFiles
    )));
  };

  getRailroadFiles(): Observable<MapFile[]> {
    return this.store.select((createSelector(
      this.getMapFilesState,
      (state: fromMapFiles.State) => state.railroadFiles
    )));
  };

  getOilGasFiles(): Observable<MapFile[]> {
    return this.store.select((createSelector(
      this.getMapFilesState,
      (state: fromMapFiles.State) => state.oilGasFiles
    )));
  };
}
