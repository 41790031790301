export class CustomDrawControl {
  constructor(opt) {
    let ctrl = this as any;
    ctrl.draw = opt.draw;
    ctrl.buttons = opt.buttons || [];
    ctrl.onAddOrig = opt.draw.onAdd;
    ctrl.onRemoveOrig = opt.draw.onRemove;
  }

  onAdd(map) {
    let ctrl = this as any;
    ctrl.map = map;
    ctrl.elContainer = ctrl.onAddOrig(map);
    ctrl.buttons.forEach((b) => {
      ctrl.addButton(b);
    });
    return ctrl.elContainer;
  }

  onRemove(map) {
    let ctrl = this as any;
    ctrl.buttons.forEach((b) => {
      ctrl.removeButton(b);
    });
    ctrl.onRemoveOrig(map);
  }

  addButton(opt) {
    let ctrl = this as any;
    let elButton = document.createElement('button');
    elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    if (opt.content) {
      if (opt.content instanceof Element) {
        elButton.appendChild(opt.content);
      } else {
        elButton.innerHTML = opt.content
      }
    }
    if (opt.events) {
      for (const event of opt.events) {
        elButton.addEventListener(event.on, event.action);
      }
    }
    let children = ctrl.elContainer.childNodes;
    let counter = 0;
    for (const node of children) {
      ctrl.elContainer.removeChild(node);
      if (counter === 2) {
        ctrl.elContainer.appendChild(elButton);
      }
      ctrl.elContainer.appendChild(node);
      counter++;
    }
    opt.elButton = elButton;
  }

  removeButton(opt) {
    for (const event of opt.events) {
      opt.elButton.removeEventListener(event.on, event.action);
    }
    opt.elButton.remove();
  }
}
