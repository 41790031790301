import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {mapConfigActions, usersActions} from '../actions'
import {catchError, exhaustMap, map, of} from "rxjs";
import {UsersService} from "../../service/users.service";
import {MapboxUser} from "../../model/mapbox-user";
import {shareMapFailure} from "../actions/users.actions";
import {MapService} from "../../service/map.service";

@Injectable()
export class UsersEffects {
  constructor(
    private actions$: Actions,
    private readonly store: Store,
    private usersService: UsersService,
    private mapService: MapService
  ) {
  }

  getUsers = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.getUsers),
      exhaustMap(action => this.usersService.getUsers().pipe(
        map(response => usersActions.getUsersSuccess({
          users: response.map((data: any) => {
            const user: MapboxUser = {
              email: data.email,
              firstName: data.name,
              lastName: data.lastName,
              userId: data.userId,
              username: data.userId,
              fullName: `${data.name} ${data.lastName}`
            }
            return user;
          })
        })),
        catchError((error: any) => of(usersActions.getUsersFailure({msg: "error"})))
      ))
    )
  );

  shareMap = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.shareMap),
      exhaustMap(action => this.mapService.shareMap(action.userMap).pipe(
        map(response => usersActions.shareMapSuccess()),
        catchError((error: any) => of(usersActions.shareMapFailure({msg: "error"})))
      ))
    )
  );

}
