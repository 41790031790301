<div *ngFor="let item of datacentersToDisplay;">
  <ng-container
    [ngTemplateOutlet]="!item.locationId ? modifyingMarker : existingMarker"
    [ngTemplateOutletContext]="{item:item}">
  </ng-container>
</div>
<ng-template #modifyingMarker let-item="item">
  <mgl-marker #myMarker [lngLat]="[item.lon, item.lat]" [draggable]="!(areMarkersBlocked | async)"
              (markerDragEnd)="onDragEnd($event, item)">
    <div class="dot-map-new">{{item.mapId}}</div>
  </mgl-marker>
  <mgl-popup [marker]="myMarker" [closeOnMove]="false" [closeOnClick]="false">
    <ng-container
      [ngTemplateOutlet]="popupTemplate"
      [ngTemplateOutletContext]="{item:item}">
    </ng-container>
  </mgl-popup>
</ng-template>

<ng-template #existingMarker let-item="item">
  <mgl-marker #myMarker [lngLat]="[item.lon, item.lat]" [draggable]="!(areMarkersBlocked | async)"
              (markerDragEnd)="onDragEnd($event, item)">
          <span #divSpan [class.bigger]="item.highligth" class="dot-map"
                [style.backgroundColor]="item.color"
                [style.transform]="'scale(' + scale  + ')'"
                [style.color]="item.font">{{item.mapId}}</span>
  </mgl-marker>

  <mgl-popup [marker]="myMarker">
    <ng-container
      [ngTemplateOutlet]="popupTemplate"
      [ngTemplateOutletContext]="{item:item}">
    </ng-container>
  </mgl-popup>
</ng-template>

<ng-template #popupTemplate let-item="item">
  <div>
    <span class="popup-tittle">{{item.provider}} {{item.building ? "- " + item.building : ''}}</span><br/><br/>
    <span><b>MW Live</b> - {{item.mwLive | number:'1.0':'en-US'}}</span><br/>
    <span><b>MW U/C</b> - {{item.mwUC | number:'1.0':'en-US'}}</span><br/>
    <span><b>MW Planned</b> - {{item.mwPlanned | number:'1.0':'en-US'}}</span><br/>
    <span><b>Data Hall Live</b> - {{item.dataHallLive | number:'1.0':'en-US'}}</span><br/>
    <span><b>Building Area</b> - {{item.buildingAreaTotal | number:'1.0':'en-US'}}</span><br/>
    <span><b>Building Tenant</b> - {{item.buildingTenant}}</span><br/>
    <span><b>Year Live</b> - {{item.firstYearOp}}</span><br/>
    <span><b>Site Type</b> - {{item.siteType}}</span><br/>
    <span><b>Product Type</b> - {{item.productType}}</span><br/>
    <span><b>Sellable IT Load</b> - {{item.sellableITLoad | number:'1.0':'en-US'}}</span><br/>
    <span><b>Campus</b> - {{item.campus}}</span><br/>

    <span><b>Lat/Long</b> - {{item.lat | number:'1.0-6'}}, {{item.lon | number:'1.0-6'}}</span><br/><br/>
    <p-button styleClass="p-button-primary primary p-button-sm" [label]="'Modify'"
              (onClick)="modifyDatacenter(item)"></p-button>
    <p-button styleClass="p-button-primary secondary p-button-sm" [label]="'Remove'"
              (onClick)="removeDatacenter(item)"></p-button>
    <p-button styleClass="p-button-primary secondary p-button-sm" icon="pi pi-copy"
              (onClick)="copyMarkerInfo(item)"></p-button>

  </div>
</ng-template>
