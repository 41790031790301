import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {AppStateService} from "./core/service/app-state.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxMapboxGLModule} from "ngx-mapbox-gl";
import {CommonModule} from "@angular/common";
import {DataCentersService} from "./core/service/data-centers.service";
import {MapService} from "./core/service/map.service";
import {MapConfigStateService} from "./core/service/map-config-state.service";
import {MessageService} from "primeng/api";
import {reducers} from "./core/ngrx";
import {MapConfigEffects} from "./core/ngrx/effects/map-config.effects";
import {MapConfigSelector} from "./core/ngrx/selectors/map-config.selector";
import {DynamicComponentService} from "./core/service/dynamic-component.service";
import {DialogService} from "primeng/dynamicdialog";
import {GeoService} from "./core/service/geo.service";
import {GeoEffects} from "./core/ngrx/effects/geo.effects";
import {GeoSelector} from "./core/ngrx/selectors/geo.selector";
import {MemoryStorage, OAuthModule} from "angular-oauth2-oidc";
import {AuthComponent} from './auth/auth.component';
import {HttpAuthInterceptor} from "./core/interceptors/http-auth.interceptor";
import {AuthGuard} from "./core/guards/auth.guard";
import {AuthSelector} from "./core/ngrx/selectors/auth.selector";
import {AuthEffects} from "./core/ngrx/effects/auth.effects";
import {DialogModule} from "primeng/dialog";
import {UsersService} from "./core/service/users.service";
import {UsersEffects} from "./core/ngrx/effects/users.effects";
import {UsersSelector} from "./core/ngrx/selectors/users.selector";
import {environment} from "../environments/environment";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {ResizableModule} from 'angular-resizable-element';
import {LoadingInterceptor} from './core/interceptors/loading.interceptor';
import {MapFilesEffects} from "./core/ngrx/effects/map-files.effects";
import {MapFilesSelector} from "./core/ngrx/selectors/map-files.selector";
import {providePrimeNG} from "primeng/config";
import Lara from '@primeng/themes/lara';

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent
  ],
  imports: [
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    CommonModule,
    DialogModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClipboardModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mapboxToken, // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    StoreModule.forRoot(reducers, {}),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production, connectInZone: true}),
    EffectsModule.forRoot([MapConfigEffects, GeoEffects, AuthEffects, UsersEffects, MapFilesEffects]),
    OAuthModule.forRoot(),
    ResizableModule
  ],
  providers: [AppStateService, DataCentersService, MapService, MapConfigStateService, MessageService, MapConfigSelector, DynamicComponentService, DialogService,
    GeoService, GeoSelector, AuthGuard, AuthSelector, UsersService, UsersSelector, MapFilesSelector, MemoryStorage, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    providePrimeNG({
      theme: {
        preset: Lara,
        options: {

        }
      }
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
