import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import * as _ from "lodash";
import {Nestable} from "../model/summary";
import {HttpClient} from "@angular/common/http";
import {AppStateService} from "./app-state.service";
import {Router} from "@angular/router";
import {Datacenter} from "../model/datacenter";
import {Observable, of, tap} from "rxjs";
import { ColorUtils } from '../util/color-utils';

@Injectable({
  providedIn: 'root'
})
export class DataCentersService {

  constructor(private http: HttpClient, private appStateService: AppStateService) {

  }

  public loadDataCenters(savedData: Datacenter[]): Datacenter[] {
    let dataCenters: Datacenter[] = [];
    for (let ob of savedData) {
      const data: any = {
        ...ob,
        entireData: [],
        auxId: 0,
        children: [],
        parent: null,
        expanded: false,
        isGlobal: false,
        label: ob.building,
        highligth: false
      };
      dataCenters.push(data);
    }
    return dataCenters;
  }

  public getDataCenters(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/data`, {responseType: 'json'}).pipe(
      tap(
        (input: any) => {
          let dataCenters: Datacenter[] = [];
          for (let ob of input.data) {
            const data: any = {
              ...ob,
              entireData: [],
              auxId: 0,
              children: [],
              parent: null,
              expanded: false,
              selected: false,
              isGlobal: false,
              color: "",
              label: ob.building,
              highligth: false,
              totalMwPlan: 0,
              totalMwLive: 0
            };
            dataCenters.push(data);
          }
          return of(dataCenters);
        }
      ));
  }

  public getMarketsData(datacenters: Datacenter[]) {
    const marketGroup: Nestable[] = []
    var grouped = _.mapValues(_.groupBy(datacenters, 'market'),
      sum => {
        return _.mapValues(_.groupBy(sum, 'provider'),
          last => {
            return {
              buildings: _.orderBy(last, "building", "asc")
            }
          });
      });
    let auxMarketGroup: Nestable[] = [];
    for (const markets in grouped) {
      const marketLabel = markets;
      const marketProvideObj = grouped[markets];
      const market: Nestable = {
        children: [],
        parent: null,
        expanded: false,
        selected: false,
        label: marketLabel,
        isGlobal: false,
        color: "",
        totalMwPlan: 0,
        totalMwLive: 0,
        entireData: []
      }
      auxMarketGroup.push(market);
      for (const provider in marketProvideObj) {
        const providerObj: Nestable = {
          isGlobal: false,
          parent: market,
          expanded: false,
          selected: false,
          children: marketProvideObj[provider].buildings,
          label: provider,
          color: "",
          totalMwPlan: 0,
          totalMwLive: 0,
          entireData: [],
        };
        for (const auxBuilding of providerObj.children) {
          const b = auxBuilding as Datacenter;
          auxBuilding.parent = providerObj;
          auxBuilding.label = b.building;
        }
        market.children.push(providerObj);
      }
      market.children = _.orderBy(market.children, "label", "asc");
    }
    auxMarketGroup = _.orderBy(auxMarketGroup, "market", "asc");
    const globalMarket: Nestable = {
      parent: null,
      expanded: false, label: "Global", children: [], selected: false, isGlobal: true,
      color: "",
      totalMwPlan: 0,
      totalMwLive: 0,
      entireData: [],
    }
    marketGroup.push(globalMarket);
    marketGroup.push(...auxMarketGroup);
    return marketGroup;
  }

  public getGroupedBuildingsByMarket(datacenters: Datacenter[], keepColor: boolean = false) {
    const markets: Array<Nestable> = new Array<Nestable>();
    let x = 0;
    for (const selection of datacenters.filter(dc=>dc.selected)) {
      const existsMarket = !!markets.filter(tp => tp.label == selection.area && selection.market == tp.auxMarket).length;
      let market: Nestable;
      let provider: Nestable;
      if (existsMarket) {
        market = markets.filter(tp => tp.label == selection.area && selection.market == tp.auxMarket)[0];
        const existsProvider = !!market.children.filter(tp => tp.label == selection.provider).length;
        if (existsProvider) {
          provider = market.children.filter(tp => tp.label == selection.provider)[0];
        } else {
          provider = {
            children: [],
            expanded: false,
            isGlobal: false,
            label: selection.provider,
            parent: undefined,
            selected: false,
            color: selection.color,
            totalMwPlan: 0,
            totalMwLive: 0,
            entireData: [],
          };
          market.children.push(provider);
        }
      } else {
        market = {
          children: [],
          expanded: false,
          isGlobal: false,
          label: selection.area,
          parent: undefined,
          selected: false,
          color: selection.color,
          totalMwPlan: 0,
          totalMwLive: 0,
          entireData: datacenters.filter(d => d.area == selection.area && d.market == selection.market),
          auxMarket: selection.market
        };
        provider = {
          children: [],
          expanded: false,
          isGlobal: false,
          label: selection.provider,
          parent: undefined,
          selected: false,
          color: selection.color,
          totalMwPlan: 0,
          totalMwLive: 0,
          entireData: [],
        };
        markets.push(market);
        market.children.push(provider);
      }
      selection.auxId = x++;
      provider.children.push(selection);
    }
    return markets;
  }

  public groupDatacentersByProvider(providers: Nestable[], selectedDatacenters: Datacenter[]) {
    const providersWithDatacenters: Nestable[] = [];
  
    providers.forEach(provider => {
      provider.color = provider.color || ColorUtils.generateRandomColor();
  
      if (!this.isProviderAlreadyGrouped(providersWithDatacenters, provider.label)) {
        provider.children = [];
  
        const providerDatacenters = selectedDatacenters
          .filter(datacenter => datacenter.selected && datacenter.provider === provider.label)
          .map(datacenter => this.assignProviderColorToDatacenter(datacenter, provider.color));
  
        provider.children.push(...providerDatacenters);
        
        providersWithDatacenters.push(provider);
      }
    });
  
    return providersWithDatacenters;
  }

  private isProviderAlreadyGrouped(groupedProviders: Nestable[], providerLabel: string): boolean {
    return !!groupedProviders.find(groupedProvider => groupedProvider.label === providerLabel);
  }

  private assignProviderColorToDatacenter(datacenter: Datacenter, providerColor: string): Datacenter {
    datacenter.color = providerColor;
    return datacenter;
  }

}
