import {ApplicationRef, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {filter, Observable, Subject, takeUntil, tap} from 'rxjs';
import {Datacenter} from "../../core/model/datacenter";
import {Nestable} from "../../core/model/summary";
import {MapConfigSelector} from "../../core/ngrx/selectors/map-config.selector";
import {Store} from "@ngrx/store";
import {mapConfigActions} from "../../core/ngrx/actions";
import * as _ from "lodash";
import {Actions, ofType} from "@ngrx/effects";

@Component({
  selector: 'app-providers-table',
  templateUrl: './providers-table.component.html',
  styleUrls: ['./providers-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProvidersTableComponent implements OnInit, OnDestroy {
  @Input()
  onlyVisible: boolean = true;
  public providers: Nestable[] = [];
  public providersOrig: Nestable[] = [];
  public providersInView: Nestable[] = [];
  public currentFilter: string = "";
  public changingColorProvider: string = null;
  public changingColor: string = null;

  public selectedProviders: Nestable[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private mapConfigSelector: MapConfigSelector, private readonly store: Store, private actions$: Actions,
              private appRef: ApplicationRef, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.mapConfigSelector.getProviders().pipe(
      tap(providers => this.handleProvidersChange(providers))
    ).subscribe();
    this.actions$.pipe(
      ofType(mapConfigActions.ZOOM_END),
      takeUntil(this.destroy$),
      tap(event => this.filterProvidersOnZoomEnd(event))
    ).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  filterProvidersOnZoomEnd(event) {
    if (this.onlyVisible) {
      const datacentersInView: Datacenter[] = (event as any).datacenters;
      this.providersInView = [];
      if (datacentersInView.length) {
        for (const dc of datacentersInView) {
          if (this.providersInView.filter(p => p.label == dc.provider).length == 0) {
            this.providersInView.push(...this.providersOrig.filter(p => p.label.toUpperCase() == dc.provider.toUpperCase()));
          }
        }
      }
      this.providersInView = this.providersInView.sort((a, b) => a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1);
      if (this.currentFilter) {
        this.providers = this.providersInView.filter(p => p.label.toUpperCase().indexOf(this.currentFilter) != -1);
      } else {
        this.providers = this.providersInView;
      }
      this.handleSelectedAfterFilter();
      this.appRef.tick();
    }
  }

  filter(dt, event) {
    this.currentFilter = event.target.value.toUpperCase();
    if (this.currentFilter) {
      this.providers = this.providersInView.filter(p => p.label.toUpperCase().indexOf(this.currentFilter) != -1);
    } else {
      this.providers = this.providersInView;
    }
    this.providers = this.providers.filter(p => p.label.toUpperCase().indexOf(event.target.value.toUpperCase()) != -1);
    this.handleSelectedAfterFilter();
  }

  handleSelectedAfterFilter() {
    const selectedAfterFilter: Nestable[] = [];
    for (const currentSelected of this.selectedProviders) {
      if (this.providers.find(p => p.label == currentSelected.label)) {
        selectedAfterFilter.push(currentSelected);
      }
    }
  }

  handleProvidersChange(providers: Nestable[]) {
    let newSelectedProviders: Nestable[] = _.cloneDeep(this.selectedProviders);
    let newProviders: Nestable[] = _.cloneDeep(providers).filter(p => p.isProviderHidden != this.onlyVisible);
    newProviders.forEach(p => {
      let index = newSelectedProviders.findIndex(sp => sp.label === p.label);
      if (index !== -1) {
        newSelectedProviders[index] = p;
      }
    });
    newSelectedProviders = newSelectedProviders.filter(nsp => newProviders.findIndex(p => p.label === nsp.label) !== -1);
    this.selectedProviders = newSelectedProviders;
    this.providers = newProviders.sort((a, b) => a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1);
    this.providersOrig = newProviders.sort((a, b) => a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1);
  }

  overProvider(provider: Nestable) {
    this.handledHighlited(provider, true);
  }

  leaveProvider(provider: Nestable) {
    this.handledHighlited(provider, false);
  }

  selectedProvidersChanged() {
    this.selectedProviders = _.cloneDeep(this.selectedProviders);
    if (this.onlyVisible) {
      this.store.dispatch(mapConfigActions.selectedProvidersChanged({
        selectedProviders: this.selectedProviders
      }));
    }
  }

  hideProvider(providers: Nestable[], visible: boolean) {
    providers.forEach(provider => {
      let index = this.selectedProviders.findIndex(sp => sp.label == provider.label);
      if (index !== -1) {
        this.selectedProviders = this.selectedProviders.filter(sp => sp.label != provider.label);
        this.store.dispatch(mapConfigActions.selectedProvidersChanged({
          selectedProviders: this.selectedProviders
        }));
      }
    });
    this.store.dispatch(mapConfigActions.toggleProviderVisibility({
      provider: providers,
      visible: visible
    }));
  }

  private handledHighlited(provider: Nestable, isHighlight: boolean) {
    this.store.dispatch(mapConfigActions.datacenterHoverChange({
      hover: isHighlight,
      value: provider.label,
      isProvider: true
    }));
  }

  colorChanged(event, provider) {
    this.changingColorProvider = provider.label;
    this.changingColor = event.value;

  }

  onShow() {
    this.appRef.tick();
  }

  onHide() {
    if (this.changingColorProvider && this.changingColor) {
      this.store.dispatch(mapConfigActions.changedDatacenterColor({
        provider: this.changingColorProvider,
        color: this.changingColor
      }));
      this.changingColorProvider = null;
      this.changingColor = null;
    }

  }

  toggleAll() {
    this.hideProvider(this.providersOrig, this.onlyVisible);
  }

}
