import {createFeatureSelector, createSelector, Store} from "@ngrx/store";
import * as fromAuth from "../reducers/auth.reducer";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MapboxUser} from "../../model/mapbox-user";
import {AppError} from "../../model/app-error";

@Injectable()
export class AuthSelector {
  constructor(private readonly store: Store) {
  }

  getAuthState = createFeatureSelector<fromAuth.State>('auth');

  getLoggedUser(): Observable<MapboxUser> {
    return this.store.select((createSelector(
      this.getAuthState,
      (state: fromAuth.State) => state.loggedUser
    )));
  };

  isLoggedOut(): Observable<boolean> {
    return this.store.select((createSelector(
      this.getAuthState,
      (state: fromAuth.State) => state.loggedOut
    )));
  };

  getAuthError(): Observable<AppError> {
    return this.store.select((createSelector(
      this.getAuthState,
      (state: fromAuth.State) => state.authError
    )));
  };
}
