export const FILE_SIZE_LIMITS = {
  MAX_FILE_SIZE_BYTES: 1073741824, // 1GB in bytes
};

export const FIBER_TYPE = "fiber"
export const POWER_TYPE = "power"
export const LAND_TYPE = "land"
export const RAILROAD_TYPE = "railroad"
export const OIL_GAS_TYPE = "oilGas"

export const DEFAULT_TILES_COLOR = "#ff69b4"
