import {Feature} from "@turf/turf";
import {
  DEFAULT_POLYGON_COLOR,
  DEFAULT_POLYGON_OPACITY,
  POLYGON_LABEL_SOURCE_TYPE
} from "../../../../consts/map.constants";
import * as _ from "lodash";
import * as turf from "@turf/turf";
import * as mapboxgl from "mapbox-gl";
import {MapPolygon} from "../../../../model/summary";
import {getMarkersInsidePolygon, isPointWithinAnyPolygon} from "../../../../util/transform.util";
import {State} from "../../map-config.reducer";
import {
  buildMapPolygonDataFromFeature,
  buildMapPolygonDataFromModel,
  buildMapPolygonModelFromFeature, updateInfoSquare
} from "./map.util";

export const updatePolygonLabel = (state: State, action): State => {
  //adding missing property to draw tool
  const exFeature = action.drawControlFeatureCollection.features.filter(f => f.id == action.feature.id)[0];
  const mapPolygonData = buildMapPolygonDataFromFeature(exFeature);


  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };

  drawFeaturesCollection.features = _.cloneDeep(action.drawControlFeatureCollection.features.filter(f => f.id != action.feature.id));
  drawFeaturesCollection.features.push(mapPolygonData[0]);

  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: _.cloneDeep(state.mapSource.data)
  };
  let initPolygonId: string = null;
  const existingFeature = (mapSource.data as any).features.filter(
    f => f.properties["id"] && action.feature.id == f.properties["id"]);
  if (existingFeature.length) {
    existingFeature[0].properties['label'] = exFeature.properties['label'] ? exFeature.properties['label'] : '';
    existingFeature[0].geometry.coordinates = mapPolygonData[2];
  } else {
    initPolygonId = action.feature.id;
    (mapSource.data as any).features.push(mapPolygonData[1]);
  }

  let source = updateInfoSquare(mapSource, drawFeaturesCollection, state.datacenters);

  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    mapSource: source,
    initPolygonId: initPolygonId
  };
}

export const showPolygonFeatures = (state: State, action): State => {
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };
  drawFeaturesCollection.features = _.cloneDeep(state.drawingFeatures.features);
  for (const mapPolygon of state.mapPolygons) {
    const mapPolygonData = buildMapPolygonDataFromModel(mapPolygon, state);
    drawFeaturesCollection.features.push(mapPolygonData[0]);
  }
  return {
    ...state,
    displayPolygons: true,
    mapPolygons: [],
    drawingFeatures: drawFeaturesCollection,
  };
}

export const hidePolygonFeatures = (state: State, action): State => {
  const mapPolygons: MapPolygon[] = [];
  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: []
  };
  for (const feature of action.drawControlFeatureCollection.features) {
    if (feature.geometry.type != "LineString") {
      const mapPolygon = buildMapPolygonModelFromFeature(feature);
      mapPolygons.push(mapPolygon[0]);
    } else {
      drawFeaturesCollection.features.push(feature);
    }
  }
  return {
    ...state,
    displayPolygons: false,
    mapPolygons: mapPolygons,
    drawingFeatures: drawFeaturesCollection,
  };
}

export const polygonSelected = (state: State, action): State => {
  const exFeature = state.drawingFeatures.features.filter(f => f.id == action.featureId)[0];
  const mapPolygonData = buildMapPolygonModelFromFeature(exFeature);
  const mapPolygon: MapPolygon = {
    ...mapPolygonData[0],
    center: mapPolygonData[1],
    datacentersInside: getMarkersInsidePolygon(_.cloneDeep(state.datacenters.filter(dc => dc.selected)), exFeature as any),
    isModifying: action.isModifying,
    isReadOnly: false
  };

  return {
    ...state,
    selectedPolygon: mapPolygon
  };
}

export const marketSelected = (state: State, action): State => {
  const feature = action.feature
  const centerComplex = getCenterPoint(feature)
  if (!isPointWithinAnyPolygon(action.clickLocation, action.drawControlFeatureCollection)) {
    const center = [centerComplex.geometry.coordinates[0], centerComplex.geometry.coordinates[1]];
    const mapPolygon: MapPolygon = {
      polygon: (feature.geometry as any).coordinates,
      center: center,
      datacentersInside: _.cloneDeep(state.datacenters.filter(dc => dc.selected && dc.area == action.marketLabel)),
      isModifying: false,
      isReadOnly: true
    };

    return {
      ...state,
      selectedPolygon: mapPolygon
    };
  }
  return {
    ...state
  };

}


export const popupSaved = (state: State, action): State => {
  //update drawing tool feature
  const mapPolygonData = buildMapPolygonDataFromModel(action.polygonData, state);

  const drawFeaturesCollection: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: _.cloneDeep(state.drawingFeatures.features.filter(f => f.id != action.polygonData.auxId))
  };
  drawFeaturesCollection.features.push(mapPolygonData[0]);


  const mapSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: "FeatureCollection",
      features: _.cloneDeep((state.mapSource.data as any).features.filter(
        f => !f.properties["id"] || action.polygonData.auxId != f.properties["id"]))
    }
  };

  (mapSource.data as any).features.push(mapPolygonData[1]);
  let source = updateInfoSquare(mapSource, drawFeaturesCollection, state.datacenters);
  return {
    ...state,
    drawingFeatures: drawFeaturesCollection,
    mapSource: source,
    selectedPolygon: null
  };
}

export const getCenterPoint = (feature) => {
  const bounds = [];
  for (const coor of feature.geometry.coordinates[0]) {
    bounds.push([coor[0], coor[1]]);
  }
  const points = turf.points(bounds);
  const centerComplex = turf.center(points);
  return centerComplex;
}
