import {Injectable} from '@angular/core';
import {CircleData, Nestable} from "../model/summary";
import * as turf from "@turf/turf";
import {UserMap} from "../model/user-map";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AppStateService} from "./app-state.service";
import {environment} from "../../../environments/environment";
import {catchError, map, Observable, of, tap} from "rxjs";
import {Datacenter} from "../model/datacenter";
import {
  INFO_SQUARE_SOURCE_TYPE,
  MARKET_CIRCLE_FILL_LAYER_ID,
  MARKET_LABEL_SOURCE_TYPE,
  MARKET_LINES_SOURCE_TYPE
} from "../consts/map.constants";
import * as _ from "lodash";
import {MapObject} from "../model/map-object";
import {AuthSelector} from "../ngrx/selectors/auth.selector";
import {MapboxUser} from "../model/mapbox-user";
import {geoActions, mapConfigActions} from "../../core/ngrx/actions";

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient, private appStateService: AppStateService, private authSelector: AuthSelector) {
  }

  public saveUserMap(userMap: UserMap): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    userMap.userId = userMap.userId ? userMap.userId : this.appStateService.user.userId;
    return this.http.post<UserMap>(`${environment.apiUrl}/maps`, userMap, httpOptions);
  }

  public savePartialUserMap(userMap: UserMap): Observable<any> {
    let toSave: UserMap = _.cloneDeep(userMap);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    toSave.userId = this.appStateService.user.userId;
    return this.http.post<UserMap>(`${environment.apiUrl}/maps/partial`, toSave, httpOptions);
  }

  public mergeMap(maps: UserMap[], userMap: UserMap, objectsToAdd: MapObject[]): Observable<any> {
    let toSave: UserMap = _.cloneDeep(userMap);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    const requestBody = {
      maps: maps,
      userMap: toSave,
      objectsToAdd: objectsToAdd
    }
    toSave.userId = this.appStateService.user.userId;
    return this.http.post<UserMap>(`${environment.apiUrl}/maps/merge`, requestBody, httpOptions);
  }

  public getUserMaps(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/maps/${this.appStateService.user.userId}`, {responseType: 'json'}).pipe(
      map((data: any) => data.data)
    );
  }

  public processCsv(mapId: string, data: string[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post(`${environment.apiUrl}/maps/csv/${this.appStateService.user.userId}/${mapId}`, {data: data}, httpOptions).pipe(
      map((data: any) => data)
    );
  }

  public shareMap(userMap: UserMap): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post(`${environment.apiUrl}/maps/share`, userMap, httpOptions).pipe(
      map((data: any) => data)
    );
  }

  public copyFromMap(mapId: string, toCopy: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.post(`${environment.apiUrl}/maps/copy/${this.appStateService.user.userId}/${toCopy}/${mapId}`, {}, httpOptions).pipe(
      map((data: any) => data)
    );
  }

  public getUserMap(userId: string, mapId: string, full: boolean): Observable<any> {
    return this.http.get(`${environment.apiUrl}/maps/${userId}/${mapId}?full=${full}`, {responseType: 'json'});
  }

  public deleteUserMap(mapId: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/maps/${this.appStateService.user.userId}/${mapId}`, {responseType: 'json'});
  }

  public getSelectedDatacenters(datacenters: Datacenter[]) {
    return datacenters.filter(dc => dc.selected);
  }

  public addMarketAreaData(markets: Nestable[]) {
    for (const market of markets) {
      const marketBounds = [];
      for (const building of market.entireData) {
        marketBounds.push([building.lon, building.lat]);
      }
      const marketPoints = turf.points(marketBounds);
      const marketCenterComplex = turf.center(marketPoints);
      const marketCenter = [marketCenterComplex.geometry.coordinates[0], marketCenterComplex.geometry.coordinates[1]];
      let bigger = 0;
      for (const point of marketBounds) {
        let line = turf.lineString([[point[0], point[1]], [marketCenter[0], marketCenter[1]]]);
        const distance = turf.length(line, {units: 'miles'});
        bigger = distance >= bigger ? distance : bigger;
      }

      const circleData: CircleData = {
        center: marketCenter,
        points: marketBounds,
        ratio: bigger,
        market: market
      }
      market.circleData = circleData;
    }
  }

  public createSourceForMarketPoints(market: Nestable): any {
    var point = turf.point([market.circleData.center[0], market.circleData.center[1]]);
    let ratio = market.circleData.ratio + ((market.circleData.ratio * 20) / 100);
    ratio = ratio <= 0 ? 1 : ratio;
    var buffered = turf.buffer(point, ratio, {units: 'miles'});

    return {
      "type": "geojson",
      "data": buffered
    }
  }

  public createPointSource(lon, lat): any {
    return {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [
                lon, lat
              ]
            }
          }
        ]
      }
    };
  }

  public createLineSource(coor: number[][]): any {
    return {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [
          this.createLineFeature(coor)
        ]
      }
    };
  }

  public createLineFeature(coor: number[][], id = null): any {
    return {
      "id": id,
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "LineString",
        "coordinates": coor
      }
    };
  }

  public createLineLayer(layerId: string, sourceId: string, lineColor: string, lineWidth: number, dashArray: number[]): any {
    return {
      "id": layerId,
      "source": sourceId,
      "type": "line",
      "paint": {
        "line-color": lineColor,
        'line-dasharray': dashArray,
        "line-width": lineWidth
      },
      filter: ['==', 'type', MARKET_LINES_SOURCE_TYPE]
    };
  }

  public createFillLayer(layerId: string, sourceId: string, lineColor: string, lineWidth: number, dashArray: number[]): any {
    return {
      "id": layerId,
      "source": sourceId,
      "type": "fill",
      "paint": {
        'fill-opacity': 0,
        'fill-outline-color': "#000000"
      },
      filter: ['==', 'type', MARKET_LINES_SOURCE_TYPE]
    };
  }

  public createInfoSquareLayer(layerId: string, sourceId: string, lineColor: string, lineWidth: number, dashArray: number[]): any {
    return {
      "id": layerId,
      "source": sourceId,
      "type": "fill",
      "paint": {
        'fill-opacity': 1,
        'fill-color': "#FFFFFF",
        'fill-outline-color': "#000000"
      },
      filter: ['==', 'type', INFO_SQUARE_SOURCE_TYPE]
    };
  }

  public createLabelLayer(layerId: string, sourceId: string, paintConfig: any, layoutConfig: any, filterType: string): any {
    return {
      'id': layerId,
      'type': 'symbol',
      'source': sourceId,
      'layout': layoutConfig,
      "paint": paintConfig,
      filter: ['==', 'type', filterType]
    }
  }

  public createPolygon(id: string, coordinates: number[][][]): any {
    return {
      "id": id,
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": coordinates,
        "type": "Polygon"
      }
    };
  }

  public onDrawCreate(event, _this, polygonControl) {
    if (event.features[0].geometry.type == "LineString") {
      _this.store.dispatch(mapConfigActions.updateDistanceLabel({
        feature: event.features[0],
        drawControlFeatureCollection: polygonControl.getAll()
      }));
    } else if (event.features[0].geometry.type == "Polygon") {
      let mapboxButtons = document.getElementsByClassName("mapbox-gl-draw_ctrl-draw-btn");
      Array.prototype.filter.call(
        mapboxButtons,
        (mapboxButton) => {
          let circleButtons = mapboxButton.getElementsByClassName("circle");
          if (circleButtons.length) {
            if (mapboxButton.classList.contains("active")) {
              mapboxButton.classList.remove('active');
            }
          }
        },
      );
      _this.store.dispatch(mapConfigActions.updatePolygonLabel({
        feature: event.features[0],
        drawControlFeatureCollection: polygonControl.getAll()
      }));
    }
  }

  public onDrawUpdate(event, _this, polygonControl) {
    if (event.features[0].geometry.type == "LineString") {
      _this.store.dispatch(mapConfigActions.updateDistanceLabel({
        feature: event.features[0],
        drawControlFeatureCollection: polygonControl.getAll()
      }));
    } else {
      _this.store.dispatch(mapConfigActions.updatePolygonLabel({
        feature: event.features[0],
        drawControlFeatureCollection: polygonControl.getAll()
      }));
    }
  }

  public onDrawDelete(event, _this, polygonControl) {
    _this.store.dispatch(mapConfigActions.removeDrawing({
      feature: event.features[0],
      drawControlFeatureCollection: polygonControl.getAll()
    }));
  }

  public onDrawRightClick(event, _this, polygonControl) {
    event.preventDefault();
    _this.store.dispatch(mapConfigActions.polygonSelected({
      featureId: event.features[0].properties["id"],
      isModifying: false
    }));
  }

  public onMarketRightClick(event, _this, polygonControl) {
    event.preventDefault();
    const polygon = event.features[0].geometry;
    _this.store.dispatch(mapConfigActions.marketSelected({
      feature: {
        geometry: {
          coordinates: polygon.coordinates
        }
      },
      drawControlFeatureCollection: polygonControl.getAll(),
      clickLocation: event.lngLat,
      marketLabel: event.features[0].properties['label']
    }));
  }

}
