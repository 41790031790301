import {createAction, props} from "@ngrx/store";
import {GeoInfo} from "../../model/geo-info";
import {LOAD_COUNTRIES_SUCCESS} from "./geo.actions";
import {MapboxUser} from "../../model/mapbox-user";
import {AppError} from "../../model/app-error";

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';

export const LOGOUT = '[Auth] Logout';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const LOGOUT_FAILURE = '[Auth] Logout Failure';

export const AUTH_ERROR = '[Auth] Auth Error';


export const login = createAction(
  LOGIN,
  props<{ user: MapboxUser }>()
);

export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{ user: MapboxUser }>()
);

export const loginFailure = createAction(
  LOGIN_FAILURE,
  props<{ msg: string }>()
);

export const logout = createAction(
  LOGOUT
);

export const logoutSuccess = createAction(
  LOGOUT_SUCCESS
);

export const logoutFailure = createAction(
  LOGOUT_FAILURE,
  props<{ msg: string }>()
);

export const authError = createAction(
  AUTH_ERROR,
  props<{ error: AppError }>()
);
