import { Component, ViewEncapsulation, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { LandMapLayer } from '../../core/model/land';
import { LandService } from '../../core/service/land.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import * as _ from 'lodash';
import { MapLayer } from '../../core/model/map-layer.interface';

@Component({
  selector: 'app-land-table',
  templateUrl: './land-table.component.html',
  styleUrls: ['./land-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService, MessageService],
})
export class LandTableComponent implements OnInit, OnDestroy {
  @Input() onlyVisible: boolean = true;
  @Output() onLayerClick = new EventEmitter<MapLayer>();
  
  lands: LandMapLayer[] = [];
  landsOrig: LandMapLayer[] = [];
  landsInView: LandMapLayer[] = [];
  selectedLands: LandMapLayer[] = [];
  currentFilter: string = "";
  changingColorLand: string = null;
  changingColor: string = null;
  
  private destroy$ = new Subject<boolean>();

  constructor(
    private landService: LandService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.listLands();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  listLands(): void {
    this.landService.listLands().pipe(
      tap(lands => {
        this.lands = lands;
        this.landsOrig = _.cloneDeep(lands);
        this.landsInView = _.cloneDeep(lands);
      }),
      takeUntil(this.destroy$)
    ).subscribe({
      error: (error) => {
        console.error('Error loading lands:', error);
      }
    });
  }

  filter(dt, event) {}

  handleSelectedAfterFilter() {}

  handleLandsChange(lands: LandMapLayer[]) {}

  overLand(land: LandMapLayer) {}

  leaveLand(land: LandMapLayer) {}

  selectedLandsChanged() {}

  hideLand() {}

  colorChanged(event, land) {}

  onShow() {}

  onHide() {}

  toggleAll() {}

  createLand() {
    // TODO: Implement land creation logic
    this.messageService.add({
      severity: 'info',
      summary: 'Create Land',
      detail: 'Functionality under development'
    });
  }

  viewLand() {
    if (this.selectedLands.length) {
      // TODO: Implementing logic for displaying land
      this.messageService.add({
        severity: 'info',
        summary: 'View Land',
        detail: `Viewing land: ${this.selectedLands[0].landType}`
      });
    }
  }

  deleteLand(landToDelete: LandMapLayer) {    
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the land <b>${landToDelete.landType}</b>?`,
      header: 'Confirm Deletion',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Yes',
      rejectLabel: 'No',
      accept: () => {
        this.landService.deleteLand(landToDelete.mapId)
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Land successfully deleted'
              });
              this.listLands();
              this.selectedLands = [];
            },
            error: (error) => {
              console.error('Error deleting land:', error);
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An error occurred while deleting the land'
              });
            }
          });
      },
      reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Cancelled',
          detail: 'Deletion cancelled'
        });
      }
    });
  }

  onLandClick(land: LandMapLayer) {
    const mapLayer: MapLayer = {
      id: land.landType,
      mapId: land.mapId,
      propertyOwner: land.propertyOwner,
      landType: land.landType,
      type: 'land',
      properties: {
        propertyOwner: land.propertyOwner,
        underDNA: land.underDNA,
        landType: land.landType
      }
    };
    
    this.onLayerClick.emit(mapLayer);
  }
}
