import {Action, createReducer, on} from "@ngrx/store";
import { geoActions } from '../actions/index'
import * as mapboxgl from "mapbox-gl";
import {MapLine, MapPolygon, Nestable} from "../../model/summary";
import * as _ from "lodash";
import {Datacenter, DatacenterBasicInfo} from "../../model/datacenter";
import {DistanceUnits, UserMap} from "../../model/user-map";

import {
  hideLineDrawings,
  showLineDrawings,
  updateDistanceLabel,
  updateDistanceUnits
} from "./util/map/line-drawing.util";
import {
  getCenterPoint,
  hidePolygonFeatures, marketSelected,
  polygonSelected, popupSaved,
  showPolygonFeatures,
  updatePolygonLabel,
} from "./util/map/polygon-drawing.util";
import {
  addedDataCenter,
  changeDatacenterColor,
  changeMwThresholdValue,
  datacenterHoverChange,
  increaseMarkersSize,
  modifiedDataCenter,
  reduceMarkersSize,
  removedDataCenter,
  resetMarkersSize,
  selectedProvidersChanged,
  toggleDatacenters,
  toggleMarkersDrag,
  toggleProviderVisibility,
  uniqueFilter,
  zoom,
  updateAZColor
} from "./util/map/data-center.util";
import {
  loadMapSource,
  loadUserMapSuccess, onMapStyleChanged,
  popupDisplayed,
  removeDrawing,
  saveMarkerToDisplay,
  updateAreas, updateInfoSquare
} from "./util/map/map.util";

import {GeoInfo} from "../../model/geo-info";
import * as mapConfigActions from '../actions/map-config.actions';
import { ColorUtils } from '../../util/color-utils';

export interface State {
  drawingFeatures: GeoJSON.FeatureCollection;
  marketCirclesFeatures: GeoJSON.FeatureCollection;
  marketLabelsFeatures: GeoJSON.FeatureCollection;
  polygonLabelsFeatures: GeoJSON.FeatureCollection;
  distanceLabelsFeatures: GeoJSON.FeatureCollection;
  mapSource: mapboxgl.GeoJSONSourceRaw;
  mapLines: MapLine[],
  mapPolygons: MapPolygon[],
  displayLines: boolean,
  displayPolygons: boolean,
  saveMapSuccess: boolean,
  saveMapError: string,
  selectedPolygon: MapPolygon,

  userMap: UserMap,
  providers: Nestable[],
  selectedProviders: Nestable[],
  existingProviders: string[],
  existingMarkets: string[],
  datacenters: Datacenter[],
  scale: number,
  scaleAux: number,
  mwThreshold: number,
  areMarkersBlocked: boolean
  readOnly: boolean;
  markerToDisplay: GeoInfo,
  units: DistanceUnits,
  initPolygonId?: string,
  existingAvailabilityZoneIds: string[];
  existingBuildingTenants: string[];
  infoLayerVisible: boolean;
  existingDataSources: string[];
  temporaryAZIds: string[];
}

export const initialState: State = {
  drawingFeatures: {
    type: "FeatureCollection",
    features: []
  },
  marketCirclesFeatures: {
    type: "FeatureCollection",
    features: []
  },
  marketLabelsFeatures: {
    type: "FeatureCollection",
    features: []
  },
  polygonLabelsFeatures: {
    type: "FeatureCollection",
    features: []
  },
  distanceLabelsFeatures: {
    type: "FeatureCollection",
    features: []
  },
  mapSource: {
    type: 'geojson'
  },
  mapLines: [],
  mapPolygons: [],
  displayLines: true,
  displayPolygons: true,
  saveMapSuccess: false,
  saveMapError: "",
  selectedPolygon: null,

  userMap: null,
  providers: [],
  selectedProviders: [],
  existingProviders: [],
  existingMarkets: [],
  datacenters: [],
  scale: 1,
  scaleAux: 1,
  mwThreshold: 0,
  areMarkersBlocked: true,
  readOnly: false,
  markerToDisplay: null,
  units: DistanceUnits.MILES,
  initPolygonId: null,
  existingAvailabilityZoneIds: [],
  existingBuildingTenants: [],
  infoLayerVisible: true,
  existingDataSources: [],
  temporaryAZIds: []
}

const mapConfigReducer = createReducer(
  initialState,
  on(mapConfigActions.nukeStore, (state, action) => {
    return {
      ...initialState
    };
  }),
  //load main map sources
  on(mapConfigActions.loadMapSource, (state: State, action): State => loadMapSource(state, action)),
  //remove drawing
  on(mapConfigActions.removeDrawing, (state: State, action): State => removeDrawing(state, action)),
  //load user map for map configuration
  on(mapConfigActions.loadUserMapSuccess, (state: State, action): State => loadUserMapSuccess(state, action)),
  //on(geoActions.centerMap, (state: State, action): State => saveMarkerToDisplay(state, action)),

  on(mapConfigActions.mapStyleChanged, (state: State, action): State => onMapStyleChanged(state, action)),


  //update polygon label
  on(mapConfigActions.updatePolygonLabel, (state: State, action): State => updatePolygonLabel(state, action)),


  //show polygon features
  on(mapConfigActions.showPolygonFeatures, (state: State, action): State => showPolygonFeatures(state, action)),
  //hide polygon features
  on(mapConfigActions.hidePolygonFeatures, (state: State, action): State => hidePolygonFeatures(state, action)),
  //polygon selected
  on(mapConfigActions.polygonSelected, (state: State, action): State => polygonSelected(state, action)),
  on(mapConfigActions.marketSelected, (state: State, action): State => marketSelected(state, action)),
  //popupsaved
  on(mapConfigActions.popupSaved, (state: State, action): State => popupSaved(state, action)),
  on(mapConfigActions.popupDisplayed, (state: State, action): State => popupDisplayed(state, action)),


  //show line features
  on(mapConfigActions.showLineFeatures, (state: State, action): State => showLineDrawings(state, action)),
  //hide line features
  on(mapConfigActions.hideLineFeatures, (state: State, action): State => hideLineDrawings(state, action)),
  //update distance label
  on(mapConfigActions.updateDistanceLabel, (state: State, action): State => updateDistanceLabel(state, action)),
  //update distance label
  on(mapConfigActions.updateAreaData, (state: State, action): State => updateAreas(state, action)),


  //toggle datacenters
  on(mapConfigActions.toggleDatacenters, (state: State, action): State => toggleDatacenters(state, action)),
  on(mapConfigActions.addedDataCenter, (state: State, action): State => addedDataCenter(state, action)),
  on(mapConfigActions.removedDataCenter, (state: State, action): State => removedDataCenter(state, action)),
  on(mapConfigActions.modifiedDataCenter, (state: State, action): State => modifiedDataCenter(state, action)),
  on(mapConfigActions.datacenterHoverChange, (state: State, action): State => datacenterHoverChange(state, action)),
  on(mapConfigActions.increaseMarkersSize, (state: State, action): State => increaseMarkersSize(state, action)),
  on(mapConfigActions.reduceMarkersSize, (state: State, action): State => reduceMarkersSize(state, action)),
  on(mapConfigActions.resetMarkersSize, (state: State, action): State => resetMarkersSize(state, action)),
  on(mapConfigActions.selectedProvidersChanged, (state: State, action): State => selectedProvidersChanged(state, action)),
  on(mapConfigActions.changedDatacenterColor, (state: State, action): State => changeDatacenterColor(state, action)),
  //on(mapConfigActions.zoom, (state: State, action): State => zoom(state, action)),
  //change color based on MW threshold
  on(mapConfigActions.mwThresholdChange, (state: State, action): State => changeMwThresholdValue(state, action)),
  on(mapConfigActions.distanceUnitsChanged, (state: State, action): State => updateDistanceUnits(state, action)),
  on(mapConfigActions.toggleProviderVisibility, (state: State, action): State => toggleProviderVisibility(state, action)),
  on(mapConfigActions.toggleMarkersDrag, (state: State, action): State => toggleMarkersDrag(state, action)),
  on(mapConfigActions.updateMarkerColor, (state, { datacenter, newProvider }) => {
    const updatedDatacenters = state.datacenters.map(dc => {
      if (dc.locationId === datacenter.locationId) {
        return {
          ...dc,
          provider: newProvider,
          color: ColorUtils.getProviderColor(newProvider, state.providers)
        };
      }
      return dc;
    });

    return {
      ...state,
      datacenters: updatedDatacenters
    };
  }),
  on(mapConfigActions.toggleInfoLayer, (state, { visible }) => ({
    ...state,
    infoLayerVisible: visible
  })),
  on(mapConfigActions.updateAZColor, (state, action) => updateAZColor(state, action)),

  on(mapConfigActions.addTemporaryAZId, (state, { azId }) => ({
    ...state,
    temporaryAZIds: [...state.temporaryAZIds, azId]
  })),
  on(mapConfigActions.clearTemporaryAZIds, (state) => ({
    ...state,
    temporaryAZIds: []
  })),
  on(mapConfigActions.resetAllHidden, (state) => ({
    ...state,
    datacenters: state.datacenters.map(dc => ({
      ...dc,
      isHidden: false
    }))
  })),
);

export function reducer(state: State | undefined, action: Action): any {
  return mapConfigReducer(state, action);
}
