<div class="sidebar-inner-content">
    <p-table
      id="powerList"
      #dt1
      selectionMode="multiple"
      [(selection)]="selectedPowers"
      [scrollable]="true"
      scrollHeight="flex"
      [value]="powers"
      [rowHover]="true"
      (onRowSelect)="selectedPowersChanged()"
      (onRowUnselect)="selectedPowersChanged()"
      [lazy]="false"
      dataKey="id"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 60px"></th>
          <th></th>
          <th style="width: 60px">
            <span *ngIf="!onlyVisible">
              <a
                (click)="toggleAll()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px; color: white;"
                class="pi pi-eye"
              ></a>
            </span>
            <span *ngIf="onlyVisible">
              <a
                (click)="toggleAll()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px; color: white;"
                class="pi pi-eye-slash"
              ></a>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-power>
        <tr [pSelectableRow]="power" class="p-selectable-row">
          <td style="width: 60px">
            <p-colorPicker
              [appendTo]="'body'"
              (click)="onShow()"
              (onHide)="onHide()"
              (onChange)="colorChanged($event, power)"
              [(ngModel)]="power.color"
            ></p-colorPicker>
          </td>
          <td style="cursor: pointer;" (click)="onPowerClick(power)">
            {{ power.supplierName }}
          </td>
          <td style="width: 60px">
            <span *ngIf="onlyVisible">
              <a
                (click)="hidePower()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px"
                class="pi pi-eye"
              ></a>
            </span>
            <span *ngIf="!onlyVisible">
              <a
                (click)="hidePower()"
                style="cursor: pointer; text-decoration: none; margin-top: 3px"
                class="pi pi-eye-slash"
              ></a>
            </span>
            <p-button
              icon="pi pi-trash"
              styleClass="p-button-sm primary btn-create"
              (onClick)="deletePower(power)"
              pTooltip="Remove Power"
              tooltipPosition="bottom"
            ></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-confirmDialog
    acceptButtonStyleClass="primary"
    rejectButtonStyleClass="secondary"
    [style]="{ width: '35vw' }"
  ></p-confirmDialog>