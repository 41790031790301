import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PowerMapLayer } from '../model/power';
import { AppStateService } from './app-state.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PowerService {
  private apiUrl = `${environment.apiUrl}/power-network`;
  private get userId(): string {
    return this.appStateService.user?.userId;
  }

  constructor(private http: HttpClient, private appStateService: AppStateService) {}

  listPowers(): Observable<PowerMapLayer[]> {
    return this.http.get<PowerMapLayer[]>(`${this.apiUrl}/${this.userId}`);
  }

  getPower(mapId: string): Observable<PowerMapLayer> {
    return this.http.get<PowerMapLayer>(`${this.apiUrl}/${this.userId}/${mapId}`);
  }

  savePower(power: PowerMapLayer): Observable<PowerMapLayer> {
    return this.http.post<PowerMapLayer>(`${this.apiUrl}`, power);
  }

  deletePower(mapId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${this.userId}/${mapId}`);
  }
} 