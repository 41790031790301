import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {Observable} from "rxjs";
import {MapboxUser} from "../../core/model/mapbox-user";
import {AuthSelector} from "../../core/ngrx/selectors/auth.selector";
import {Store} from "@ngrx/store";
import {authActions} from "../../core/ngrx/actions";
import {environment} from "../../../environments/environment";
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserCardComponent implements OnInit {
  user: Observable<MapboxUser> = this.authSelector.getLoggedUser();
  displayName: Observable<string>;
  avatarUrl: Observable<string>;

  constructor(private oauthService: OAuthService, private authSelector: AuthSelector, private readonly store: Store) {
    this.displayName = this.user.pipe(
      map(user => {
        if (user && user.firstName && user.lastName) {
          return `${user.firstName} ${user.lastName}`;
        } else if (user && user.email) {
          return user.email;
        } else {
          return 'User';
        }
      })
    );

    this.avatarUrl = this.user.pipe(
      map(user => user && user.avatar ? user.avatar : 'https://gravatar.com/avatar/00000000000000000000000000000000?d=mp')
    );
  }

  ngOnInit(): void {
  }

  logout() {
    this.store.dispatch(authActions.logout());
  }

  get isProduction() {
    return environment.production
  }
}
