<div class="sidebar-inner-content">
  <p-table id="filesList" [scrollable]="true"
           scrollHeight="flex" [value]="_mapFiles"
           [lazy]="false" dataKey="fileId">
    <ng-template pTemplate="header">
      <tr>
        <th colspan="3" style="width:100%;height: 20px;"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-mapFile>
      <tr [pSelectableRow]="mapFile" class="p-selectable-row">
        <td style="width:60px;">
          <p-colorPicker [disabled]="!mapFile.selected" [appendTo]="'body'"
                         (onHide)="onHide()" (onChange)="colorChanged($event, mapFile)"
                         [(ngModel)]="mapFile.color"></p-colorPicker>
        </td>
        <td style="width:70%; text-align: left;">
          {{mapFile.fileName}}
        </td>
        <td style="width:30%;">
          <span *ngIf="mapFile.selected"><a (click)="toggleSelect(mapFile)"
                                            style="cursor:pointer;text-decoration: none;margin-top: 3px;"
                                            class="pi pi-eye"></a></span>
          <span *ngIf="!mapFile.selected"><a (click)="toggleSelect(mapFile)"
                                             style="cursor:pointer;text-decoration: none;margin-top: 3px;"
                                             class="pi pi-eye-slash"></a></span>
          &nbsp;
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
